import React, {useEffect, useState } from "react";
import {IonSpinner, IonGrid, IonPage, IonContent, IonItem, IonButton, useIonViewDidEnter, IonHeader, IonList, IonRow, IonCol } from '@ionic/react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import "./Payment.css";
import "./Page.css";

import Header from '../components/Header';
import FooterMenu from '../components/FooterMenu';
import bandoneon from '../assets/images/bandoneon.png'
import { Link } from 'react-router-dom';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
//const promise = loadStripe("pk_test_51HJrNeFTe9cEFhnnDPkZuBAdXzXLRRtJKHmUfiitNgXNWsSABxFzzE3W5lma9UQFAfiwPNplrbEpyNsGcbbtUjpN00rQX1fGe9");
const promise = loadStripe("pk_live_51HJrNeFTe9cEFhnn8cAru0luRHP8uFYAloZvEggMSeqrSeFNztN28o7B3fHms5HjPxTbdFmrUNNnjTnXJUvG9q1h00BWXYWtuM");

export default function Payment(props) {

    const [plId, setPlId] = useState(0);
    const [playlist, setPlaylist] = useState(null);
    var description = {}
    var plName = "";
    var tandaQty = 0;
    var setQty = 0;
    var duration = 0;

    const [amount, setAmount] = useState(0.0);
    const [isReady, setIsReady] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [error, setError]  = useState('');
    

    const provideStatus = (status) => {
        console.debug('provideAmount');
        if (status.error){
            setError(status.error);
            setPlaylist(null);
        }
        if (amount !== status.amount) {
            setAmount(status.amount);
        }

        if (isPaid !== status.isPaid){
            setIsPaid(status.isPaid);
        }
        setIsReady(true);


    }


    useIonViewDidEnter(() => {
        console.debug("useIonViewDidEnter");
        setAmount(0);
        setIsReady(false);
        setError('');
        var pl = JSON.parse(sessionStorage.getItem('loadedPlaylist'));

        console.debug("playlist");

        console.debug(pl)
        console.debug(JSON.parse(pl.description).name);
        setPlaylist(pl);
        description = JSON.parse(pl.description);
        plName = description.name;
        tandaQty = pl.sets.length * 6;
        setQty = pl.sets.length;
        duration = pl.duration_ms / 1000 / 60;

        //setPlId(id);

    });


    return (
        <IonPage>
            <Header title="Check out">

            </Header>


            <IonContent>
                {!isReady? <IonSpinner className="spinnerCenter"></IonSpinner> : null}
                <div >
                    <IonItem disabled={!isReady} hidden={isPaid || playlist == null}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonItem>

                                        {playlist != null && !isPaid && error==='' ?
                                            <p style={{ fontSize: 12 }} >
                                Playlist name: {JSON.parse(playlist.description).name}<br />
                                Number of tandas: {playlist.sets.length * 6}<br />
                                Number of sets: {playlist.sets.length}<br />
                                Duration (approximately): {Math.floor(playlist.duration_ms / 1000 / 60)} minutes<br />
                                Price: ${amount}<br></br> 
                                            </p>
                                            : null
                                        }
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem >
                                        <div>
                                            <p style={{ fontSize: 12 }}>After successful payment you will be able to:</p>
                                            <ol style={{ fontSize: 12, paddingLeft: "10%" }}>
                                                <li>
                                                    Import the playlist into your Spotify library
                                                </li>
                                                <li>
                                                    Play the songs in Partylonga app using the app player. The app will allow you to automate the playback process by playing the songs in correct order with shortened
                                                    cortinas
                                                </li>
                                                <li>
                                                    Access Partylonga radio ad-free 24x7 for duration of one month since the date of purchase.
                                                </li>
                                            </ol>

                                        </div>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <p>
                                        Disclaimer: the purchase is final. Partylonga.com does not collect credit card information you provide. 
                                    </p>
                                </IonCol>
                            </IonRow>



                            <IonRow>
                                <IonCol>

                                    <div style={{ fontSize: 12, paddingTop: 20, paddingBottom: 20 }}>
                                        <Elements stripe={promise}>
                                            <div style={{ padding: 20, fontSize: 12 }}>
                                                
                                            After clicking "Pay" button your card will be charged ${amount}
                                            </div>

                                            <CheckoutForm plId={props.match.params.id} returnStatus={provideStatus} />
                                        </Elements>
                                    </div>
                                    {/* </div> */}
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                    <div hidden={!isPaid}>
                        <p>You already purchased this playlist.</p>
                        <Link style={{alignSelf:"center"}} to="/page/MilongaList"   > Go to paltylonga list</Link>
                    </div>
                    <div hidden={error===''}>
                        <p>{error}</p>
                        <Link style={{alignSelf:"center"}} to="/page/MilongaList"   > Go to paltylonga list</Link>
                    </div>

                </div>

            </IonContent>
            <FooterMenu></FooterMenu>
        </IonPage>
    );
}