import React, { useState } from 'react';
import Track from './Track';
import axios from 'axios';
import {IonItem, IonGrid, IonRow, IonCol, IonChip, IonIcon, IonList, IonListHeader, IonLabel, IonNote} from '@ionic/react';
import { thumbsUpSharp, thumbsDownSharp, thumbsUpOutline, thumbsDownOutline} from 'ionicons/icons'
import {millisToMinutesAndSeconds } from '../pages/utils.js'


const Tanda = ({tanda, idx, setIdx, plRef, playingTrack, trackCb, tracksRef, isPreview}) => {
    const [isLiked, setIsLiked] = useState(null);
    var tandaIdx = idx;
    const getKind = (kind) => {
        var rslt = 'Milonga';
        switch (kind) {
          case 't':
            rslt = "Tango";
            break
          case 'v':
            rslt = "Vals";
            break
        }
        return rslt;
      }

    const likeItem = (event, tanda, isUp) => {
        var item = document.getElementById(event.target.id);
        var tanda_id = item.name;
        //if(up){
          var up = document.getElementById('up_' + tanda_id);
          var upOk = document.getElementById('up_ok_' + tanda_id);
          var dn = document.getElementById('dn_' + tanda_id);
          var dnOk = document.getElementById('dn_ok_' + tanda_id);
          
          if (isUp){
              up.hidden = true;
              upOk.hidden = false;
              dnOk.hidden = true;
              dn.hidden = false;
          } else {
            dn.hidden = true;
            dnOk.hidden = false;
            upOk.hidden = true;
            up.hidden = false;
          }

          
          tanda.liked = isUp;
    
          axios('/api/vote/tanda?id=' + tanda_id + '&up=' + isUp);
          setIsLiked(isUp);
       
      }

    var hdrcls = "tanda-header-" + tanda.kind;
    var itemcls = "tanda-item-" + tanda.kind;
    var sort_by = tanda.sort_by;
    var sort_param = (Math.round(tanda.sort_param * 100) / 100).toFixed(2);
    //itemcls = 'tanda'
    var color = "tertiary";
    switch (tanda.kind) {
        case 'm':
            color = "tertiary";
            break;
        case 'v':
            color = "secondary";
            break;
        case 't':
            color = "primary";
            break;
    }
    var imagesStr = "";
    for (var i = 1; i < tanda.tanda_tracks.length; i++) {
        var track = tanda.tanda_tracks[i];
        if (track.images != "") {
            imagesStr = track.images;
            break;
        }
    }
    var mainTrack = tanda.tanda_tracks[1];

    // console.debug("mainTrack");
    // console.debug(tanda.tanda_tracks[1]);

    // //300x300 image
    // console.debug("mainTrack");
    // console.debug(mainTrack);
    if (imagesStr != "") {
        var imageUrl = JSON.parse(imagesStr)[1].url;
        var valence = (Math.round(tanda.valence * 100) / 100).toFixed(2);
        var duration = millisToMinutesAndSeconds(tanda.duration_ms);
        var tandaStyle = {
            margin: 0,
            background: 'url("' + imageUrl + '")'
        }
    }

    return (
        <IonItem key={tanda.id} className='ion-no-padding' className={itemcls}  >
            <IonGrid className='ion-no-padding ion-no-margin' fixed style={{ window: 'auto', width: '100%', margin: 0 }} >
                <IonRow>
                    <IonCol  >

                        <div className='ion-no-padding'>
                            <IonList>
                                <IonListHeader>
                                    <IonLabel color={color}>
                                        <h1 style={{ paddingRight: 20 }}>
                                            <div>
                                                <span style={{ float: "left" }}>{tanda.artist}</span>
                                                <IonChip style={{ float: "right" }} >
                                                    <IonIcon name={tanda.id} id={'dn_' + tanda.id} hidden={tanda.liked != null && !tanda.liked} style={{ paddingLeft: 10 }} src={thumbsDownOutline}
                                                        onclick={(event) => {

                                                            likeItem(event, tanda, false);


                                                        }}

                                                    ></IonIcon>
                                                    <IonIcon name={tanda.id} id={'dn_ok_' + tanda.id} hidden={tanda.liked == null || tanda.liked} style={{ paddingLeft: 10 }} src={thumbsDownSharp}
                                                        onclick={(event) => {

                                                            likeItem(event, tanda, false)


                                                        }}

                                                    ></IonIcon>
                                                    <IonIcon name={tanda.id} id={'up_' + tanda.id} hidden={tanda.liked && tanda.liked != null} style={{ paddingRight: 10 }} src={thumbsUpOutline}
                                                        onclick={(event) => {

                                                            likeItem(event, tanda, true)

                                                        }}
                                                    >
                                                    </IonIcon>
                                                    <IonIcon name={tanda.id} id={'up_ok_' + tanda.id} hidden={!tanda.liked || tanda.liked == null} style={{ paddingRight: 10 }} src={thumbsUpSharp}
                                                        onclick={(event) => {

                                                            likeItem(event, tanda, true);

                                                        }}
                                                    >
                                                    </IonIcon>
                                                </IonChip>
                                            </div>
                                        </h1>

                                        <IonNote>{getKind(tanda.kind)} - [{sort_by}: {sort_param}; {duration} min]</IonNote>
                                    </IonLabel>
                                </IonListHeader>
                                {tanda.tanda_tracks.map((track, idx) => 
                                {
                                    var isPlaying = false;
                                    if (playingTrack.track.eltId == track.spotify_id){
                                        isPlaying = playingTrack.isPlaying;
                                    }
                                  return  <Track key={idx}  track={track} idx={idx} tandaIdx={tandaIdx} setIdx={setIdx} plRef={plRef} isPlaying={isPlaying} trackCb={trackCb} tracksRef={tracksRef} isPreview={isPreview}></Track>
                                }
                                
                                )}
                            </IonList>
                        </div>

                    </IonCol>
                    <IonCol className='right' size='auto'>
                        <span className='right'>
                            <img style={{ width: '100%' }} className='ion-hide-sm-down right' src={imageUrl}></img>
                        </span>
                    </IonCol>

                </IonRow>

            </IonGrid>
        </IonItem>
    )

}
export default Tanda