import {IonFooter, IonMenuToggle, IonIcon, NavContext, IonModal, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, withIonLifeCycle, IonCheckbox, IonButton, IonItem, IonLabel, IonNav, IonItemGroup, IonSelect, IonSelectOption, IonCard, IonAlert, IonList, IonRow, IonCol, IonItemDivider, IonSpinner, IonRouterLink, IonRouterOutlet, IonGrid, IonText, useIonViewDidEnter } from '@ionic/react';
import React, { useEffect, useState, FormEvent, SyntheticEvent, useContext } from 'react';
import * as reactRouterDom from "react-router-dom";
import { RouteComponentProps, Redirect, useHistory } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import { checkbox } from 'ionicons/icons';
import SubmitAlert from './SubmitAlert';
import axios from 'axios';
import { Link } from 'react-router-dom';
//import { attachProps } from '@ionic/react/dist/types/components/utils';
import { isContext } from 'vm';
import { PlaylistContext } from '../App';
import {globalEventListener} from './utils.js'
import Select, { Async } from "react-select";
import DynamicSelect from '../components/DynamicSelect';
//import "react-select/dist/react-select.css";
import { menuSharp} from 'ionicons/icons'

import Header from '../components/Header';
import FooterMenu from '../components/FooterMenu';

var artLst;
//var cortinaLst: [];
//artLst = [];
//var cortinaLst: any[] = [];
const MilongaFormPage = ({ match }) => {
  const [goToPl, setGoToPl] = useState(false);
  const { navigate } = useContext(NavContext);
  const playlistContext = useContext(PlaylistContext);

  const [artLst, setArtLst] = useState([]);
  const [formData, setFormData] = useState([]);
  const [artists, setArtists] = useState([]);
  const [cortinas, setCortinas] = useState("");
  const [duration, setDuration] = useState("60");
  const [format, setFormat] = useState("4x3");
  const [cortinaLst, setCortinaLst] = useState([]);

  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [alertInputs, setAlertInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  //setShowSubmitAlert(true);
  //var alertInputs = [] as any[];

  

  const handleSubmit = (e) => {
    var alertInputs = [];
    console.log(e)
    console.log(artists);
    console.log(cortinas);
    console.log('submit');
    artists.map((art, idx) => {
      alertInputs.push(
        {
          name: 'art' + idx,
          type: 'text',
          label: art,
        }
      )
    })
    setAlertInputs(alertInputs);
    //setShowSubmitAlert(true);
    //setShowModal(true);
    submitPlaylistData();

  }

  const createPlaylist = () => {

  }

  // const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
  //   //new FormData(event.target)
  //   alert("submitForm");
  //   event.preventDefault();
  //   console.log("submitForm");
  //   //return false;

  // }

  // document.addEventListener('submit', e => {

  //console.log("addEventListener");

  //   const form = document.querySelector('form')!;
  //   form.onsubmit = async () => {
  //     alert("addEventListener");
  //     const data = new FormData(form);
  //     //rslt = await axios.post("/api/build", data: data)
  //     fetch("/api/build", {
  //       body: data
  //     }).then(data => {
  //       return data.json();
  //     }
  //     ).then(jsonData => {
  //       console.log(jsonData);
  //     }
  //     );

  //     return false; // prevent reload
  //   }
  //   const formEvent = e as unknown as FormEvent;
  //   //fetch(formEvent.target.action)


  // })


  const addArtist = (e) => {
    var elt = e.target;
    var cb = e.detail;
    if (!cb.checked) {
      var art = artists.filter(e => e !== elt.name);
      setArtists(art);
    } else {
      setArtists([...artists,
      elt.name]);
    }
  }

  const addAllArtists = (e, kind) => {
    var elt = e.target;
    var cb = e.detail;
    console.log('addAllArtists:' + artists.length);
    //if (!cb.checked) {
    
      artLst.filter((item) => { return item.kind == kind }).map((art, idx)=>{
        var artCb = document.getElementById('cb_'+kind+'_'+idx);
        console.log(artCb);
        artCb.checked = cb.checked;

      })
    //}

  }

  const history = reactRouterDom.useHistory();


  const submitPlaylistData = () => {

    setShowModal(false);

    const id = createPlaylist();

    console.log("Submit");
    setGoToPl(true);
    setIsLoading(true);
    const getData = async () => {

      const query = JSON.stringify(
        {
          artists: artists,
          cortinas: cortinas,
          duration: duration,
          format: format

        }
      );
      const config = {
        url: "/api/build/?" + query,
        headers: {
          "Token": sessionStorage.getItem("refresh_token")
        }
      }
      console.log("query:" + query);
      //const jsonData = await axios("/api/build/?" + query);
      try {
        const jsonData = await axios(config)
        console.log(jsonData);
        console.log("jsonData.data.data:");
        var milonga_id = jsonData.data.id;
        console.log("jsonData.data.id:");
        console.log(jsonData.data.id);
        var url = { pathname: "/page/MilongaPlaylist/" + milonga_id };
        var pl = jsonData.data.data;
        setIsLoading(false);
        history.push(url);
      } catch (err) {
        setError("Authorization error. Please re-login.");
        sessionStorage.setItem('loggedIn', "false");
        sessionStorage.setItem('access_token', '');
        console.log(err);

      }


    };
    getData();
  }
  const fetchData = async () => {
    setIsLoading(true);
    var user_id = localStorage.getItem('user_id');
    try{
    const jsonData = await axios('/api/artists?user_id=' + user_id + "&access_token=" + sessionStorage.getItem('access_token'));
    // .catch((error)=>{
    // });
    // // setTimeout(() => {
    //console.log("Timeout expired");
    console.log(jsonData);
    setArtLst(jsonData.data.artists);
    setCortinaLst(jsonData.data.cortinas);
    console.log(cortinaLst);
  } catch(ex){
    setError("Authentication error. Please relogin");
  }
    setIsLoading(false);
    // }, 2);

  }

  const getCortinas = ()=>{
    fetchData();
    var elt = document.getElementById('cortinaSelect');
    // elt as HTMLSelectElement
    // elt?.innerHTML
  }

  const [optLoaded, setOptLoaded] = useState(false);
  const [options, setOptions] = useState([]);
  const handleLoadOptions = () => {
    //let options
    //setOptLoaded(true);
    //optionsLoaded: true,
    var opts = [
          { value: "one", label: "One" },
          { value: "two", label: "Two" }
        ]
    
    
    console.log("handleLoadOptions");
    setTimeout(() => {
        var opts = [
          { value: "one", label: "One" },
          { value: "two", label: "Two" }
        ]
        console.log("setTimeout");
        setIsOptLoading(false);
        setOptions(opts);
        //setState({ 

        //})
    }, 2000);
  };

  const [isOptLoading, setIsOptLoading] = useState(false);

  const maybeLoadOptions = () => {
    console.log('maybeLoadOptions');
    if (!optLoaded) {
      setIsOptLoading(true);
      handleLoadOptions();
    }
  }

  useIonViewDidEnter(()=>{
    console.log("MilongaForm.useIonViewDidEnter");
    fetchData().then(()=>
      {
        console.log(cortinaLst);
      }
    );
    
  })

  useEffect(() => {
    const pageId = 'MilongaForm';
    const mlWrapper = document.getElementById(pageId);
    if (mlWrapper){
      if(mlWrapper.onclick == null)
        mlWrapper.addEventListener('click', (e)=>{
          globalEventListener(pageId, e);
        });
     }

    //fetchData();
  }, []
  )
  if (goToPl) {
    console.log("goToPl:" + goToPl);
    // return(
    //   // <IonRouterOutlet>
    //   //   <Redirect  to="/page/MilongaPlaylist/"/>
    //   // </IonRouterOutlet>
    // );
  }



  return (
    <IonPage id='MilongaForm' className="ion-page">

      {/* <IonModal isOpen={showModal}>

        <IonContent className="modal-wrapper">

          <IonItem className="modal-wrapper" >Milonga cortinas playlist:</IonItem>
          <div className="modal-wrapper">
            <IonItem>
              {cortinas}
            </IonItem>
            <IonItem >Milonga artists/orchestras:</IonItem>
            <IonContent>
              <IonList>
                {artists.map((art, idx) => {
                  return (
                    <IonItem key={idx}>
                      <IonLabel>{art}</IonLabel>
                    </IonItem>);
                })}

              </IonList>
            </IonContent>
            <IonRow className="button-row">
              <IonCol>
                <IonButton onClick={() => setShowModal(false)}>
                  Cancel
            </IonButton>
              </IonCol>
              <IonCol>
                <IonButton onClick={submitPlaylistData}>
                  Submit
            </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonContent>

      </IonModal>
      {isLoading && (<IonSpinner name="lines" className="spinnerCenter" />)} */}

      <Header title="Create playlist"></Header>
      <IonContent>
        {/* <IonToolbar> */}
          <IonGrid style={{width:'100%'}}>
            <IonRow>
              <IonCol >
                <IonButton disabled={artists.length < 3 || cortinas == null} type="button" onClick={handleSubmit} >Submit</IonButton>
              </IonCol>
              {/* <IonCol >
              </IonCol> */}
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
              <span style={{paddingRight: 20}}> Duration:

                </span>
              {/* </IonCol>
              <IonCol size='9'> */}
                  <IonSelect style={{width: '100%'}} title="Duration:" value={duration}
                    onIonChange={e => setDuration(e.detail.value)}
                  >
                    <IonSelectOption key='d1' value="60">1 set(6 tandas)</IonSelectOption>
                    <IonSelectOption key='d3' value="120">2 sets(12 tandas)</IonSelectOption>
                    <IonSelectOption key='d5' value="180">3 sets(18 tandas)</IonSelectOption>
                    <IonSelectOption key='d6' value="240">4 sets(24 tandas)</IonSelectOption>
                    <IonSelectOption key='d7' value="300">5 sets(30 tandas)</IonSelectOption>
                    <IonSelectOption key='d8' value="3000">Max</IonSelectOption>
                  </IonSelect>

                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol >
                <IonItem>
                  <span style={{paddingRight: 20}}> Cortinas:</span>

                {/* </IonItem> */}
              {/* </IonCol>
              <IonCol > */}
                {/* <IonItem> */}
                {/* <Select
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    labelKey="label"
                    valuekey="value"
                    autoload={false}
                    isLoading={isOptLoading}
                    options={options}
                    onFocus={maybeLoadOptions}
                  />   */}
                  {/* <IonSelect id='cortinaSelect'
                    value={cortinas}
                    interface="popover"
                    onClick={e=>getCortinas()}
                    onIonChange={e => setCortinas(e.detail.value)}
                    placeholder="Select cortinas playlist or default cortinas will be used">
                    {cortinaLst.map((playlist) => (
                      <IonSelectOption key={playlist.spotify_id} value={playlist.name}><IonText>{playlist.name} {playlist.id}</IonText></IonSelectOption>
                    ))}
                  </IonSelect> */}
                  {/* <span> */}
                  <DynamicSelect style={{maxWdth: '100%'}} setIsLoading={setIsLoading} setError={setError}></DynamicSelect>
                  {/* </span> */}
                </IonItem>
              </IonCol>
              </IonRow>
              <IonRow>
              <IonCol>
              <IonItem>
                <span style={{paddingRight: 20}}>Format:</span>
              {/* </IonCol>
              <IonCol> */}
                
                  <IonSelect style={{width: '100%'}} title="Format:" value={format}
                    onIonChange={e => setFormat(e.detail.value)}
                    placeholder="Select milonga format">
                  
                  
                    <IonSelectOption key='d1' value="4x3">4x3</IonSelectOption>
                    <IonSelectOption key='d2' value="3x3">3x3</IonSelectOption> 
                    {/* <IonSelectOption key='d3' value="3x2">3x2</IonSelectOption>
                    <IonSelectOption key='d4' value="3x1">3x1</IonSelectOption>
                    <IonSelectOption key='d5' value="2x1">2x1</IonSelectOption> */}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow> 
          </IonGrid>

        {/* </IonToolbar> */}
      
      


        {/* <form id="input_form" name="input_form" onSubmit={submitForm} action="" > */}



        {/* <div className='formRow'> */}
        <IonGrid>
          <IonRow className="ion-align-items-start">
            <IonCol className="col-6">
              <IonItem className="card">
                <IonHeader>
                  <h2 >Orchestras</h2>
                </IonHeader>
                <IonCheckbox className='formCheckBox' onIonChange={(e)=>
                {
                  console.log("IonCheckbox")
                  addAllArtists(e, 'o')
                }
                }></IonCheckbox>

              </IonItem>
              {artLst.filter((item) => { return item.kind == 'o' }).map((artist, index) => (
                <IonItem key={index} className="card">

                  <IonLabel>{artist.name}</IonLabel>
                  <IonCheckbox onIonChange={e => addArtist(e)} id={'cb_o_' + index} key={index} name={artist.name} className='formCheckBox' title={artist.name}>{artist.name}</IonCheckbox>

                </IonItem>
              ))}
            </IonCol>
            <IonCol className="col-6" >
              <IonItem className="card">
                <IonHeader>
                  <h2>Singers</h2>
                </IonHeader>
                <IonCheckbox className='formCheckBox' onIonChange={(e)=>
                {
                  console.log("IonCheckbox")
                  addAllArtists(e, 's')
                }
                }></IonCheckbox>

              </IonItem>
              {artLst.filter((item) => { return item.kind == 's' }).map((artist, index) => (
                <IonItem key={index} className="card">

                  <IonLabel>{artist.name}</IonLabel>
                  <IonCheckbox onIonChange={e => addArtist(e)} id={'cb_s_'+index} key={index} name={artist.name} className='formCheckBox' title={artist.name}>{artist.name}</IonCheckbox>

                </IonItem>
              ))}
              {/* </div> */}
              {/* </form> */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonAlert
        cssClass="alert"
        isOpen={error != ""}
        message={error}
        buttons={[{
          text: 'Okay',
          handler: () => {
            setError("");
            setIsLoading(false);
            console.log('Confirm Okay');
            var url = { pathname: "/page/Login" };
            history.push(url);


          }
        }]}
      />
    <FooterMenu></FooterMenu>
    </IonPage>
  );
};

export default MilongaFormPage;
