import {IonCardContent, IonCard, IonCol, IonRow, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonGrid, useIonViewDidEnter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import './Page.css';
import axios from 'axios';
import {globalEventListener, millisToMinutesAndSeconds } from './utils.js'
import { isNull } from 'util';
import FooterMenu from '../components/FooterMenu';

//const Page: React.FC<RouteComponentProps<{ name: string; }>> = ({ match }) => {
function MilongaList() {

  const [toPl, setToPl] = useState(false);
  const [milongas, setMilongas] = useState([]);
  const [error, setError] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [milongaId, setMilongaId] = useState(-1);
  const [isPlEnabled, setIsPlEnabled] = useState(false);
  const [skipCort, setSkipCort] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [cardId, setCardId] = useState(-1);
  // const openPlaylist = (milongaId) => {
  //   console.log("openPlaylist")
  //   //history.push("/page/MilongaPlaylist");
  //   //setToPl(true);
  //   //setIsPlEnabled(true);

  // }
  const fetchData = async () => {

    try {
      if (sessionStorage.getItem("access_token") === "undefined" || isNull(sessionStorage.getItem("access_token"))) {
        console.log("MilongaList: Authorization error ");
        console.log(sessionStorage);
        throw "Authorization error";
      }
      const config = {
        url: "/api/milongas/",
        headers: {
          "Token": sessionStorage['refresh_token']
        }
      }
      //const jsonData = await axios("/api/milongas/" + "?token=" + sessionStorage['refresh_token'], config);
      const jsonData = await axios(config);
      var rslt = jsonData;
      console.log(rslt);
      if (rslt.statusText === 'Forbidden'){
        throw "Authorization error";
      }
      setMilongas(rslt.data.results);
    } catch (error) {
      var msg = "Authorization error. Please re-login." 
      console.log("MilongaList: " + msg);
      setError(msg);
      sessionStorage.setItem('loggedIn', "false");
      //sessionStorage.setItem('access_token', '');
      console.log("MilongaList: error:");
      console.log(error);

    }
  }

  useIonViewDidEnter(()=>{
    fetchData();

  })

  useEffect(
    
    () => {
      sessionStorage.setItem('state', 'MilongaListLoaded');
      const pageId = 'MilongaList';
      const mlWrapper = document.getElementById(pageId);
      if (mlWrapper){
        if(mlWrapper.onclick == null)
          mlWrapper.addEventListener('click', (e)=>{
            globalEventListener(pageId, e);
          });
       } 


      
      fetchData();
      setIsPlEnabled(false);

    }, []
  );

  return (

    <IonPage id='MilongaList'>
      <IonHeader hidden={isPlEnabled}>
        <IonToolbar >
          <IonGrid>
            <IonRow>
              <IonCol size='2'>
                <IonMenuButton></IonMenuButton>

              </IonCol>
              <IonCol >
                <IonTitle class="header" color="primary">
                <h1>My playlists</h1>
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader hidden={isPlEnabled}>
      <IonTitle style={{ marginRight: '20%' }}><IonLabel className="ion-text-center"><h1>My playlists</h1></IonLabel>

                          </IonTitle>
      
        <IonToolbar style={{ textAlign: 'center' }}>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonLabel className="ion-text-center"><IonChip>
                          <IonLabel color='primary' style={{marginLeft: 10, marginRight: 10}}>
                            Skip Cortinas
                        
                            <IonToggle checked={skipCort} style={{marginLeft: 10, marginRight: 10}} onIonChange={e => {
                setSkipCort(e.detail.checked)
                sessionStorage.setItem('skipCortinas', JSON.stringify(e.detail.checked));
              }
              } />
                          </IonLabel>
                          </IonChip>
                          </IonLabel>
          </IonButtons>
          
        </IonToolbar>

      </IonHeader> */}

      <IonContent hidden={isPlEnabled}>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonItem className="ion-text-center" hidden={error.length == 0}>
          <IonLabel>{error}</IonLabel>
        </IonItem>
        <IonList hidden={error != ''}>
          {milongas.map((milonga, idx) => {
            //{"artists": {"Angel D'Agostino": null, "Anibal Troilo": null, "Carlos Di Sarli": null, " Domingo Federico": null, "Domingo Federico": null, "Edgardo Donato": null, "Juan D'Arienzo": null, "Lucio Demare": null, "Miguel Calo": null, "Osvaldo Fresedo": null, "Pedro Laurenz": null}, "description": "Milonga", "id": 315, "date_time": "03/30/20 23:36:01"} 
            console.log(milonga.description.description);
            var digest = JSON.parse(milonga.description);
            var url = { pathname: "/page/MilongaPlaylist/" + milonga.id };
            var artists = "";
            for (var art in digest.artists) {
              artists += art + "(" + digest.artists[art] + "), ";
            }
            return (
              <IonItem key={milonga.id}  className="milonga" detail >
                <IonGrid>
                  
                    <IonRow className={milonga.paid_amount>0?'milonga_paid':'milonga'}   >
                      <IonCol  >
                        <IonItem routerLink={url}><a className={milonga.paid_amount>0?'milonga_paid':'milonga'}>{digest.name}</a></IonItem>
                      </IonCol>

                      <IonCol  size='auto' onClick={() => {
                            //console.debug("track.id clicked: " + track.spotify_id)
                            setCardId(milonga.id);
                            console.debug("cardId: " + cardId)
                            if (!isCardVisible) {
                              setIsCardVisible(true);
                            }
                          }}>       
                        <div style={{textAlign: 'center'}} hidden={(isCardVisible && cardId === milonga.id )}>
                          <IonCard className="padding"><p style={{textAlign: 'center'}}>{new Date(digest.date_time).toLocaleString("en-US", { year: 'numeric', month: 'numeric', day: 'numeric', hour:'numeric', minute:'numeric' })} </p></IonCard>
                          <IonCard className="padding" style={{textAlign: 'center'}}>{millisToMinutesAndSeconds(milonga.duration_ms)} min</IonCard>
                        </div>
                        </IonCol>
                        <IonCol className='ion-hide-sm-down right'  >
                          <IonItem>
                        <IonCard>{artists}</IonCard>
                        </IonItem>
                      </IonCol>                        
                    </IonRow>
                    {/* <IonRow>
                      <IonCol>
                        <IonItem>{artists}</IonItem>
                      </IonCol>
                      </IonRow> */}
                </IonGrid>
                <IonCard hidden={!(isCardVisible && cardId === milonga.id )}>
                                                  <IonCardContent> 
                                                    {artists}
                                                  </IonCardContent>
                                                  <IonButton fill="outline" onClick={() => {
                                                    setIsCardVisible(false);
                                                  }}>Ok</IonButton>
                                                </IonCard>

              </IonItem>
            );
          }
          )}
        </IonList>

      </IonContent>
    <FooterMenu></FooterMenu>
    </IonPage>
  );
};

export default MilongaList;
