import React from 'react';
import { IonRow, IonCol, IonIcon, IonCardContent, IonCard, IonButton, IonItem, IonImg, IonGrid } from '@ionic/react';
import { play, pause} from 'ionicons/icons';

class Track extends React.Component {

    constructor(props) {
        super(props);
        this.track = this.props.track;
        this.idx = this.props.idx;
        this.tandaIdx = this.props.tandaIdx;
        this.setIdx = this.props.setIdx;
        this.plRef = this.props.plRef;
        this.trackCb = this.props.trackCb;
        this.tracksRef = this.props.tracksRef;
        this.isPlaying = this.props.isPlaying;
        this.state = {
            cardId: "",
            isCardVisible: false,
            isPlaying: false,
            isPreview: true
        }
        this.trackRef = React.createRef();
        this.isPreview=this.props.isPreview;

    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.isPlaying != this.props.isPlaying){
            this.setState({isPlaying: this.props.isPlaying});
            
        }
        //console.debug("componentDidUpdate");
        //console.debug(this.props);
        //console.debug(this.state);

        if (this.props.isPreview !== this.state.isPreview){
            this.setState({isPreview: this.props.isPreview});
        }

        //console.debug("trackRef");
            //console.debug(this.trackRef);
            if (this.state.isPlaying ){
                this.trackRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
                
            }

    }

    render() {

        var sfx = "";
        if (this.track.kind == 'c') {
            sfx = '-c'
            var skipCort = sessionStorage.getItem('skipCortinas');
            if (skipCort && skipCort === "true") {
                return (<div hidden></div>)
            }
        }
        var trkcls = "clickable-track" + sfx;
        
        if (this.props.isPlaying){
            
            trkcls = 'track-highlight'
            
        }
        this.track.idx = this.idx * (this.tandaIdx + 1) * (this.setIdx + 1);
        this.tracksRef.current.push("spotify:track:" + this.track.spotify_id);
        this.plRef.current[this.track.spotify_id] = this.track;
        
        
        return(
        <IonGrid>
            <IonRow>
               {this.state.isPreview && <IonCol size='1'>
                    {/* <div name={track.spotify_id} key={track.spotify_id}> */}
                    {this.state.isPreview 
                    ?<IonIcon className="vertical-center" onClick={(e) => {
                        
                        this.setState({isPlaying: !this.state.isPlaying});
                        this.props.trackCb(this.track.spotify_id, this.state.isPlaying ? 'playing' : 'paused');

                    }} icon={this.state.isPlaying ? pause : play}></IonIcon>

                    : null}

                    {/* </div> */}

                </IonCol>
    }

                <IonCol>
                    <IonItem key={this.track.id} detail onClick={() => {

                        //console.debug("track.id clicked: " + this.track.spotify_id)
                        this.setState({cardId: this.track.spotify_id});
                        //console.debug("cardId: " + this.state.cardId)
                        // if (!this.state.isCardVisible) {
                            this.setState({isCardVisible: !this.state.isCardVisible});
                        // }

                    }}>
                        <div ref={this.trackRef} className={trkcls} id={this.track.spotify_id} key={this.track.spotify_id}>{this.track.kind == 'c' ? 'Cortina: ' : ''} {this.track.title}
                        </div>
                    </IonItem>
                    <IonCard hidden={!(this.state.isCardVisible && this.state.cardId === this.track.spotify_id)}>
                        <IonCardContent>
                            <audio id={'aud_' + this.track.spotify_id} controls>
                                <source src={this.track.preview_url.replace('"', '')} type="audio/mpeg"></source>
                                                          Your browser does not support the HTML5 Audio element.
                                                        </audio>
                            <IonImg style={{ width: '60%' }} src={JSON.parse(this.track.images)[0].url} />
                            {this.track.artist}
                        </IonCardContent>
                        <IonButton id={'btn_' + this.track.spotify_id} fill="outline" onClick={(e) => {
                            this.setState({isCardVisible: false});
                            //console.debug(e);
                            //alert(e);

                        }}>Ok</IonButton>
                    </IonCard>
                </IonCol>

            </IonRow>
        </IonGrid>

        )
    }
}
export default Track