import axios from 'axios';
import { Cipher } from 'crypto';

export function millisToMinutesAndSeconds(millis, addSconds=false) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if(addSconds){
      return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    return minutes;
  }

export const globalEventListener = (src, event) => {
    // const isButton = event.target.nodeName === 'BUTTON';
    console.log(event);
    //(spotify_id=act['user_id'], page=act['page'], action=act['action'], value=act['value']
    var value = {}
    //value.name = event.target.name
    value.id = event.target.id
    value.title = event.target.title
    value.text = event.target.innerText
    console.log(value);

    var data = {
      'user_id' : localStorage.getItem('user_id'),
      'page' : src,
      'action' : event.target.tagName,
      'value': JSON.stringify(value)
    }
    const config = {
      //url: "/api/register",
      headers: {
        "Token": sessionStorage.getItem("refresh_token")
      }
    }

    axios.put('/api/register', data);

    // if (!isButton) {
    //   return;
    // }

    // console.dir(event.target.id);
  };

  