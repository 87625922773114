import React, {useEffect, useState, useContext, useRef } from 'react';
import {IonProgressBar, IonButton} from '@ionic/react'


const DynamicProgressBar = ({getProgress})=>{
    const [barPrgr, setBarProgr] = useState(0)
    const prevProgr = useRef(1);
    const currProgr = useRef(0);
 
    useEffect(()=>{
        setInterval(() => {
            var progr = getProgress();
            // var step = 0;
            //console.warn("DynamicProgressBar: " + progr);

            // console.warn(progr);
            // console.warn(currProgr.current);
            // console.warn(prevProgr.current);
            // console.warn(barPrgr);
            // if (progr + step*5 < currProgr.current){
            //     currProgr.current = 0;
            // }
            // if (progr == prevProgr.current){
            //     // if (step == 0){
            //     //     step = (progr - barPrgr)/5;
            //     // }
            //      //update period
            //     console.warn('step: ' + step);
            //     currProgr.current += step;
            //     setBarProgr(currProgr.current);
            // } else {
            //     step = (progr - prevProgr.current)/5;
            setBarProgr(progr);
            //     currProgr.current = prevProgr.current;
                
            // }
            // prevProgr.current = progr;
            
        }, 1000);
    

    }, [])

    return(   
        <IonProgressBar value={barPrgr} style={{verticalAlign: "bottom"}} >
            <IonButton>song</IonButton>
        </IonProgressBar>
    )
}

export default DynamicProgressBar;
