import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonTextarea, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import {
  IonApp,
  IonItem,
  IonLabel,
  IonInput,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonRange,
  IonButton
} from "@ionic/react";


/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import Header from '../components/Header';

/* Theme variables */
//import "./theme/variables.css";

let renderCount = 0;
let initialValues = {
  fullName: "",
  subject: "",
  email: "",
  message: ""
};

const Contact = () => {
  const [alertMsg, setAlertMsg] = useState("");

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange"
  });

  const [data, setData] = useState();
  renderCount++;

  /**
   *
   * @param _fieldName
   */
  const showError = (_fieldName) => {
    let error = (errors)[_fieldName];
    return error ? (
      <div style={{ color: "red", fontWeight: "bold" }}>
        {error.message || "Field Is Required"}
      </div>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const onSubmit = (data) => {
    //alert(JSON.stringify(data, null, 2));
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data, null, 2)
  };
    fetch("api/contact", requestOptions).then(response => response.json())
    .then(jsonData=>{
      setData(data);
    })
    
    setAlertMsg("Thank you for the feedback!");
    reset(initialValues);


  };

  return (
      <IonPage>
        <Header title='Contact us'></Header>
        <IonContent>
          <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 18 }}>
            {/* <span className="counter">Render Count: {renderCount}</span> */}

            <IonItem>
              <IonLabel>Name:</IonLabel>
              <Controller id='fullName'
                as={IonInput}
                control={control}
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  console.log("fullName", selected.detail.value);
                  return selected.detail.value;
                }}
                name="fullName"
                rules={{
                  required: true,
                  minLength: { value: 4, message: "Must be 4 chars long" }
                }}
              />
            </IonItem>
            {showError("fullName")}

            <IonItem>
              <IonLabel>Email:</IonLabel>
              <Controller id='email'
                as={IonInput}
                control={control}
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  return selected.detail.value;
                }}
                name="email"
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "invalid email address"
                  }
                }}
              />
            </IonItem>
            {showError("email")}
            {/* <Controller
              as={
                <IonRadioGroup>
                  <IonListHeader>
                    <IonLabel>
                      <h1>Manufacturers</h1>
                    </IonLabel>
                  </IonListHeader>
                  <IonItem>
                    <IonLabel>Apple</IonLabel>
                    <IonRadio value="apple" />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Amazon</IonLabel>
                    <IonRadio value="amazon" />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Microsoft</IonLabel>
                    <IonRadio value="microsoft" />
                  </IonItem>
                </IonRadioGroup>
              }
              control={control}
              name="techCos"
              rules={{ required: true }}
              onChangeName="onIonChange"
              onChange={([selected]) => {
                console.log(selected.detail.value);
                return selected.detail.value;
              }}
            /> */}

            <IonItem style={{horizontalAlign:"left"}} >
              <IonGrid>
                <IonRow>
                  <IonCol size='3'>
              <IonLabel>Subject:</IonLabel></IonCol>
              <IonCol ><IonLabel><Controller 
                as={
                  <IonSelect placeholder="Select One">
                    <IonSelectOption value="new_feature">Request New Feature</IonSelectOption>
                    <IonSelectOption value="question">Question</IonSelectOption>
                    <IonSelectOption value="report_problem">Report problem</IonSelectOption>
                    <IonSelectOption value="sales">Clients</IonSelectOption>
                  </IonSelect>
                }
                control={control}
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  console.log(selected.detail.value);
                  return selected.detail.value;
                }}
                name="subject"
                rules={{ required: true }}
              /></IonLabel></IonCol>
              
            </IonRow>
          </IonGrid>
            </IonItem>

            {/* <IonItem>
              <Controller
                as={
                  <IonRange min={-200} max={200} color="secondary">
                    <IonLabel slot="start">-200</IonLabel>
                    <IonLabel slot="end">200</IonLabel>
                  </IonRange>
                }
                control={control}
                name="rangeInfo"
                onChangeName="onIonChange"
                onChange={([selected]) => {
                  console.log(selected.detail.value);
                  return selected.detail.value;
                }}
                rules={{ required: true }}
              />
            </IonItem> */}
            <IonItem>
            <IonLabel>Message</IonLabel>
              <Controller as={IonTextarea}
              control = {control}
              name="message"
              onChangeName='onIonChange'
              onChange={([selected]) => {
                console.log(selected.detail.value);
                return selected.detail.value;
                //.detail.value;
              }}
              rules={{
                required: true,
                minLength: { value: 10, message: "Please enter some text" }
              }}
              
              />
            </IonItem>
            {showError("message")}
            <IonItem>
              {/* <IonLabel>
                formState.isValid: {(formState.isValid === true).toString()}
              </IonLabel> */}
            </IonItem>
            {/* {data && (
              <pre style={{ textAlign: "left" }}>
                {JSON.stringify(data, null, 2)}
              </pre>
            )} */}

            <IonButton
              type="button"
              onClick={() => {
                reset(initialValues);
              }}
            >
              Reset Form
            </IonButton>
            <span style={{padding:'20px'}}></span>
            <IonButton type="submit" disabled={formState.isValid === false}>
              Submit
            </IonButton>
          </form>
        </IonContent>
        <IonAlert
            cssClass="alert"
            isOpen={alertMsg != ""}
            message={alertMsg}
            buttons={[{
              text: 'Okay',
              handler: () => {
                setAlertMsg("");
                console.debug('Confirm Okay');
              }
            }]}
          >

          </IonAlert>
      </IonPage>

  );
};

export default Contact;
