import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import React, { useEffect } from 'react';
import './Page.css';
import { useState, useRef } from 'react';
import bandoneon from '../assets/images/bandoneon.png'
import Header from '../components/Header';
import FooterMenu from '../components/FooterMenu';


function RadioPlayer(props) {

  console.log('env:');
  console.log(process.env);

  var initState = {
    "icestats": {
      "admin": "",
      "host": "",
      "location": "",
      "server_id": "",
      "server_start": "",
      "server_start_iso8601": "",
      "source": {
        "audio_info": "",
        "channels": 2,
        "genre": "",
        "listener_peak": 3,
        "listeners": 0,
        "listenurl": "",
        "samplerate": 0,
        "server_description": "",
        "server_name": "",
        "server_type": "",
        "server_url": "",
        "stream_start": "",
        "stream_start_iso8601": "",
        "title": "",
        "dummy": null
      }
    }
  }

  const [streamData, setStreamData] = useState(initState);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const titleRef = useRef("");

  const getTitleFromData = (data, icecast_data) => {
    try {
      console.log("getTitleFromData");
      console.log(data);
      console.log(icecast_data);
      setStreamData(initState);
      var fullTitle = ""
     
      if (data.error) {
        var arr = icecast_data.split(';');
        fullTitle = arr[0] + '-' + arr[1];
      } else {
        var title = String(data.title);
        if (title.indexOf('-') > 0) {
          title = title.split('-')[0];
        }

        fullTitle = title + '-' + data.artist;
      }

      console.log("title: " + titleRef.current);

      var src_title = String(fullTitle);
      console.log("src_title: " + src_title);
      if (titleRef.current != src_title) {
        setTitle(src_title);
        titleRef.current = src_title
        setImageUrl(data.image_url);
      }
    } catch (ex) {
      console.log("Stream is offline:");
      console.log(ex);
    }
  }

  const getIceCastState_1 = () => {
    fetch("/api/radio_track").then((data) => {
      console.log(data);
      return data.json();
    }).then(data => {
      console.log('icecast:');
      console.log(data);
      getTitleFromData(data);
      setTimeout(getIceCastState, 5000);
    }
    ).catch(error => {
      console.log("getIceCastState:")
      console.log(error);
    }
    )
  }

  const getIceCastState = () => {
    fetch(process.env.REACT_APP_ICECAST + "/airtime_status").then((data) => {
      console.log(data);
      return data.json();
    }).then(data => {
      console.log('icecast:');
      console.log(data);
      try {
        console.log(data.icestats.source.title);
        setStreamData(initState);
        console.log("title: " + titleRef.current);

        var src_title = String(data.icestats.source.title);
        console.log("src_title: " + src_title);
        if (titleRef.current != src_title) {
          titleRef.current = src_title

          var init = {
            method: 'GET',

            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
          var file = data.icestats.source.title.split(';')[2].replace('&', '9191');
          
          var uri = encodeURI('/api/radio_track?file=' + file);

          var icecast_data = data.icestats.source.title
          fetch(uri, init).then(response => {
            return response.json();
          }).then(
            (data) => {
              
              console.debug('icecast_data');
              console.debug(icecast_data);
              getTitleFromData(data, icecast_data);

            }

          )

        }
      } catch (ex) {
        console.log("Stream is offline:");
        console.log(ex);
      }
      setTimeout(getIceCastState, 5000);
    }
    ).catch(error => {
      console.log(error);
    }
    )
  }

  useEffect(() => {
    getIceCastState();
  }, []);

  return (
    <IonPage>
      <Header title='Argentine Tango Radio'></Header>
      {/* <IonHeader>

        <IonToolbar style={{ textAlign: 'center' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle style={{ marginRight: '10%' }}>Argentine Tango Radio</IonTitle>
        </IonToolbar>
      </IonHeader> */}

      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Argentine Tango Radio</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <div className='container'>
          {/* <div> */}
            <picture>
              <source srcSet={imageUrl} ></source>
              <img src={bandoneon} style={{ width: '300px', height: '300px' }} />
            </picture>
            <div>{title.split('-')[0]}
            </div>
            <div>{title.split('-')[1]}
            </div>
            <iframe src='/player.html' width="320px" frameBorder="0" >
            </iframe >
          {/* </div> */}
        </div>

      </IonContent>
      <FooterMenu></FooterMenu>
    </IonPage>
  );
};

export default RadioPlayer;
