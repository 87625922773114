import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonToggle,
  IonButton,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {card, cardOutline, cardSharp, homeSharp, logOutSharp, helpBuoy, help, helpBuoyOutline, helpBuoySharp, optionsOutline, optionsSharp, maleFemale, radio, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logIn, musicalNotes, logInSharp, musicalNotesSharp, listOutline } from 'ionicons/icons';
import './Menu.css';
import { useState } from 'react';

// interface MenuProps extends RouteComponentProps {
//   selectedPage: string;
// }

// interface AppPage {
//   url: string;
//   iosIcon: string;
//   mdIcon: string;
//   title: string;
// }

const appPages = [
  {

    title: 'Home',
    url: '/page/Login',
    iosIcon: homeSharp,
    mdIcon: homeSharp
  },
  {

    title: 'Create Milonga Playlist',
    url: '/page/MilongaForm',
    iosIcon: listOutline,
    mdIcon: listOutline
  },
  {

    title: 'My Partylongas',
    url: '/page/MilongaList',
    iosIcon: maleFemale,
    mdIcon: maleFemale
  },
  {

    title: 'Now playing',
    url: '/page/MilongaPlaylist/0',
    iosIcon: musicalNotes,
    mdIcon: musicalNotesSharp
  },
  {

    title: 'Radio',
    url: '/page/Radio/',
    iosIcon: radio,
    mdIcon: radio
  },
   {
    title: 'Help',
    url: '/page/Help',
    iosIcon: help,
    mdIcon: heartOutline
  },
  //  {
  //   title: 'Dashboard',
  //   url: '/page/Dashboard',
  //   iosIcon: optionsOutline,
  //   mdIcon: optionsSharp
  // },
  {

    title: 'Contact us',
    url: '/page/Contact/',
    iosIcon: mailSharp,
    mdIcon: mailSharp
  },
  // {

  //   title: 'Pay',
  //   url: '/page/Payment/',
  //   iosIcon: cardSharp,
  //   mdIcon: cardOutline
  // },
  // {
  //   title: 'Log Out',
  //   url: 'https://accounts.spotify.com/logout/',
  //   iosIcon: logOutSharp,
  //   mdIcon: logOutSharp
  // },
  //{
  //   title: 'Archived',
  //   url: '/page/Archived',
  //   iosIcon: archiveOutline,
  //   mdIcon: archiveSharp
  // },
  // {
  //   title: 'Trash',
  //   url: '/page/Trash',
  //   iosIcon: trashOutline,
  //   mdIcon: trashSharp
  // },
  // {
  //   title: 'Spam',
  //   url: '/page/Spam',
  //   iosIcon: warningOutline,
  //   mdIcon: warningSharp
  // }
];

const labels = ['Friends', 'Notes'];

const Menu = ({ selectedPage }) => {
  const [skipCort, setSkipCort] = useState(false);

  // Query for the toggle that is used to change between themes
  const toggle = document.querySelector('#themeToggle');

  // if (toggle) {
  //   // Listen for the toggle check/uncheck to toggle the dark class on the <body>
  //   toggle.addEventListener('ionChange', (ev) => {

  //     document.body.classList.toggle('dark', ev.detail.checked);
  //   });

  //   const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  //   // Listen for changes to the prefers-color-scheme media query
  //   prefersDark.addListener((e) => checkToggle(e.matches));

  //   // Called when the app loads
  //   const loadApp = () => {
  //     checkToggle(prefersDark.matches);
  //   }

  //   // Called by the media query to check/uncheck the toggle
  //   const checkToggle = (shouldCheck) => {
  //     toggle.checked = shouldCheck;
  //   }
  // }

  useEffect(() => {
  // Query for the toggle that is used to change between themes
    const toggle = document.querySelector('#themeToggle');
    const checkPlayer = document.querySelector('#checkPlayer');
    var isDarkStr = localStorage.getItem('isDark');
    var isDark = true;
    if (isDarkStr){
      isDark = isDarkStr == 'true';
    }
    document.body.classList.toggle('dark', isDark);

    
    if (toggle) {
      toggle.checked = isDark;
      console.log('toggle is not null');
      // Listen for the toggle check/uncheck to toggle the dark class on the <body>
      toggle.addEventListener('ionChange', (ev) => {
        console.log('addEventListener');

        document.body.classList.toggle('dark', ev.detail.checked);
        localStorage.setItem('isDark', ev.detail.checked);
      });

      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

      // Listen for changes to the prefers-color-scheme media query
      prefersDark.addListener((e) => checkToggle(e.matches));

      // Called when the app loads
      const loadApp = () => {
        checkToggle(prefersDark.matches);
      }

      // Called by the media query to check/uncheck the toggle
      const checkToggle = (shouldCheck) => {
        toggle.checked = shouldCheck;
      }
    }
  })


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>
          <IonNote></IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={selectedPage === appPage.title ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel style={{ padding: 16 }}>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          {/* <IonMenuToggle> */}
            <IonItem>
            <IonIcon slot="start" icon={logOutSharp} />
            <a style={{ padding: 16 }} href='https://accounts.spotify.com/logout/'>Log Out</a>
            </IonItem>
            <IonItem >
          <IonLabel>Toggle dark Theme: </IonLabel>
          <IonToggle id="themeToggle" />
        </IonItem>
          {/* </IonMenuToggle> */}
        </IonList>
        

        <IonItem >
          {/* <IonLabel>Find player: </IonLabel>
          <IonButton id="checkPlayer" onClick={()=>{

            //fetch("spotify:album:0sNOF9WDwhWunNAHPD3Baj");

          }}>Check Player</IonButton> */}
          <a href="spotify:album:0sNOF9WDwhWunNAHPD3Baj">Check Player</a>
        </IonItem>

        {/* <IonItem style={{height: "100%"}}>
            <IonLabel>Skip Cortinas: </IonLabel>
            <IonToggle checked={skipCort} onIonChange={e => {
                setSkipCort(e.detail.checked)
                localStorage.setItem('skipCortinas', JSON.stringify(e.detail.checked));
              }
              } />
          </IonItem> */}

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
