import React, { useState } from 'react';
import axios from 'axios';
import {IonSelect, IonSpinner, IonSelectOption, IonText, IonLabel } from '@ionic/react';
import '../pages/Page.css';

const DynamicSelect = ({setError})=>{
    const [cortinas, setCortinas] = useState("");
    const [cortinaLst, setCortinaLst] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        setIsLoading(true);
        var user_id = localStorage.getItem('user_id');
        try{
        const jsonData = await axios('/api/artists?user_id=' + user_id + "&access_token=" + sessionStorage.getItem('access_token'));
        console.log(jsonData);
        setCortinaLst(jsonData.data.cortinas);
        console.log(cortinaLst);
      } catch(ex){
        setError("Authentication error. Please relogin");
      }
        setIsLoading(false);
        // }, 2);
    
      }

    const getCortinas = ()=>{
        fetchData();
        var elt = document.getElementById('cortinaSelect');
        // elt as HTMLSelectElement
        // elt?.innerHTML
      }

    return(
        <div style={{width: '100%'}}>
        <IonSelect 
        
        id='cortinaSelect'
        value={cortinas}
        interface="popover"
        onClick={e=>getCortinas()}
        onIonChange={e => setCortinas(e.detail.value)}
        placeholder="Select cortinas playlist or default cortinas will be used">
        {cortinaLst.map((playlist) => (
          <IonSelectOption key={playlist.spotify_id} value={playlist.name} ><IonLabel>{playlist.name} {playlist.id}</IonLabel></IonSelectOption>
        ))}
      </IonSelect>
      {/* {isLoading && (<IonSpinner name="lines"/>)} */}
      </div>
    )
}

export default DynamicSelect;