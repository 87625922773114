import { IonAlert, IonRow, IonLabel, IonIcon, IonCol, IonGrid, IonModal, IonButton } from '@ionic/react';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { isNull } from 'util';
import { reloadCircleSharp, pauseCircle, playCircleOutline, playSkipForwardCircle } from 'ionicons/icons';


const Player = (props) => {
    var setAlertMsg = props.setAlertMsg;
    var player = props.player;
    const getPlRef = props.getPlRef;
    var trkProgr = props.trkProgr;
    var trackProgressRef = props.trackProgressRef;
    var tmoutRef = props.tmoutRef;
    var processPlayerError = props.processPlayerError;
    var playList = props.playList;
    var tracks = props.tracks;

    var plRef = null;
    const [isAlertOpen, setIsAlertOpen] = useState(false);


    var [isPlay, setIsPlay] = useState(false);
    var [isPaused, setIsPaused] = useState(false);
    //var [isNext, setIsNext] = useState(false);
    var wpTmout = useRef(null);

    const transferPlaybackIG = async ([device_id]) => {
        console.log("transferPlaybackIG");
        console.log(device_id);
        var token = sessionStorage.getItem('access_token');
        var bearer = 'Bearer ' + token;
        var url = "https://api.spotify.com/v1/me/player";//?device_id=" + device_id;
        const requestParams = {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': bearer,

            },
            body: JSON.stringify({
                device_ids: [device_id],
                play: true
            })
        }
        const response = await fetch(url, requestParams);

    }

    player.transferMyPlayback = transferPlaybackIG;

    player.setAccessToken(sessionStorage.getItem('access_token'));
    var currentEltId = null;
    const currEltRef = useRef(currentEltId);

    const stopStartupTrack = async (state) => {
        console.debug("stopStartupTrack");
        console.debug(state);
        if (state.item != null) {
            var currentTrack = state.item.uri;
            if (currentTrack === "spotify:track:5cAv1ghdmA5w6FktZws9Qh") { //localStorage.getItem('startupTrack')
                console.debug("stopStartupTrack:" + currentTrack);
                player.skipToPrevious();
                var rslt = player.pause();
                await rslt.then(() => {
                    return;
                }

                )
            }

        }
    }

    const processPlayerState = () => {
        try {
            //player.getMyCurrentPlaybackState({ market: 'US' }).then((state) => {
            player.getMyCurrentPlaybackState().then((state) => {
                console.debug("getMyCurrentPlaybackState:");
                console.debug(state);
                if (state.is_playing) {
                    stopStartupTrack(state);
                    console.debug("playing:");
                    console.debug(state.item);
                    plRef = props.getPlRef();
                    try {

                        console.debug("plRef:");
                        console.debug(plRef);
                        var id = state.item.id; //uri.split(":")[2];// "spotify:track:6kWJvPfC4DgUpRsXKNa9z9
                        console.debug("id:" + id);
                        console.debug("name:" + state.item.name);

                        var dict = plRef.current;
                        console.log(dict);
                        var track = dict[id];
                        if (track == undefined)
                            return;
                        try {
                            var duration = track.duration_ms;
                        } catch (err) {
                            console.error(err);
                            console.debug("id:" + id);
                            console.debug(dict);
                            console.debug(dict[id]);

                        }
                        if (track.end_ms > 0) {
                            duration = track.end_ms;
                        }

                        //setTrkProgr(state.progress_ms/duration);
                        trkProgr = state.progress_ms / duration;
                        trackProgressRef.current = trkProgr;
                        var tout = duration - state.progress_ms;
                        tmoutRef.current = tout;
                        console.debug("tmout:" + tout);
                        console.debug("found:");
                        console.debug(dict[id]);
                        var activeElt = document.getElementById(id);
                        console.debug(activeElt.className);
                        console.debug("currEltRef.current:");
                        console.debug(currEltRef.current);
                        if (currEltRef.current && currEltRef.current != id) {
                            var currTrack = dict[currEltRef.current];
                            if (currTrack) {
                                var currTrackClass = 'track-' + currTrack.kind;
                                document.getElementById(currEltRef.current).className = currTrackClass;
                                currEltRef.current = id;
                                activeElt.className = " track-highlight";
                                activeElt.scrollIntoView({ block: "center", behavior: "smooth" });
                            } else {
                                currEltRef.current = activeElt.id;
                            }
                        } else {
                            currEltRef.current = activeElt.id;
                        }
                        sessionStorage.setItem('currentTrackId', currEltRef.current);

                        if (track) {
                            if (track.kind == 'c') {
                                tmoutRef.current = 5000;
                                console.debug("cortina:");
                                console.debug(track);
                                if (state.progress_ms > track.end_ms) {
                                    player.skipToNext();
                                }
                            }
                        }
                    } catch (ex) {
                        console.debug(plRef.current);
                        console.debug("exception:" + ex);

                    }
                    if (!isPlay) {
                        //  setIsPlay(true);
                        //  setIsPaused(false);
                    }
                } else {
                    // if (state.is_playing) {
                    //     if (!isPaused && isPlay){
                    //      setIsPaused(true);
                    //      setIsPlay(false);
                    //     }
                    // }
                }
            }).catch((error) => processPlayerError(error));
        } catch (ex) {
            console.debug("exception1:" + ex);
        }
    }



    const playFunc = (device_id, track) => {
        player.transferMyPlayback([device_id]).catch(error => processPlayerError(error));
        player.getMyCurrentPlaybackState().then((state) => {
            console.log("getMyCurrentPlaybackState");
            console.debug(state);

            if (!state.is_playing) {
                var currentPl = sessionStorage.getItem("currentPl");
                console.debug("currentPl:" + currentPl);
                if (state.progress_ms == 0 || state.item.uri === "spotify:track:5cAv1ghdmA5w6FktZws9Qh" || currentPl != playList.id) {
                    sessionStorage.setItem("currentPl", playList.id);
                    console.debug("playing");
                    player.play(track);

                } else {
                    stopStartupTrack(state);
                    player.play();
                    processPlayerState();
                }
                setIsPlay(true);

            } else {
                setIsPlay(true);
            }
        }).catch((error) => {
            processPlayerError(error);

        });

    };

    const activateDevice = (device, cntr) => {
        cntr -= 1;
        console.log("activateDevice:" + cntr);
        if (cntr < 0)
            return;

        player.transferMyPlayback([device.id]).then(() => {
            player.getMyCurrentPlaybackState().then(resp => {
                if (device.is_active) {
                    playFunc(device.id);
                    sessionStorage.setItem('device_id', device.id);
                } else {
                    setTimeout(activateDevice(device, cntr), 1500);

                }
            });
        });

    };

    useEffect(() => {
        if (!isNull(wpTmout.current)) {
            clearInterval(wpTmout.current);
            wpTmout.current = null;
        }

        processPlayerState();

        console.debug("tmoutRef.current: ");
        console.debug(tmoutRef.current);
        if (isNull(wpTmout.current)) {
            console.debug("Setting interval");
            wpTmout.current = setInterval(processPlayerState, 5000);
        }
        // return () => {
        //     console.debug("cleaning");
        //     clearInterval(wpTmout.current);
        //     wpTmout.current = null;
        //   };
    });



    return (


        <IonGrid className='ion-text-center ion-no-margin'>
            <IonRow>
                <IonCol size='3'>
                    <IonLabel className='vertical-center ion-text-center'><IonIcon icon={reloadCircleSharp} size="large" onClick={() => {
                        var d = {
                            "uris": tracks.current,
                        }
                        console.debug("playList.id:");
                        console.debug(playList.id);
                        sessionStorage.setItem("currentPl", playList.id);
                        player.play(d).then(() => {
                            setAlertMsg("");
                            setIsPlay(true);
                        }).catch(error => processPlayerError(error));

                    }} /></IonLabel>
                </IonCol>
                <IonCol size='4' >
                    <IonLabel className='ion-text-center'><IonIcon icon={playCircleOutline} hidden={isPlay} className="large-icon vertical-center " onClick={() => {
                        var d = {
                            "uris": tracks.current,
                        }
                        var device_id = sessionStorage.getItem('device_id');
                        //console.log("Web Player: " + device_id);

                        player.getMyDevices().then((resp) => {
                            console.log("getMyDevices 1");
                            console.log(resp);
                            if (resp.devices.length == 0) {
                                console.log("No active devices");
                                setIsAlertOpen(true);
                                //setAlertMsg("Please start your Spotify player and click 'Play' button again.");
                            } else {
                                console.log("getMyDevices 2");
                                resp.devices.forEach(device => {
                                    console.log(device);
                                    if (!device.is_active) {
                                        var cntr = 5;

                                        activateDevice(device, cntr);

                                    } else {
                                        console.log("getMyDevices 3");
                                        // if (device.id === device_id){
                                        playFunc(device.id, d);

                                        // } 
                                    }

                                });
                            }
                        });


                    }} /></IonLabel>

                    <IonIcon icon={pauseCircle} hidden={!isPlay} className="large-icon vertical-center" onClick={() => {
                        player.pause().catch(error => processPlayerError(error));
                        setIsPlay(false);
                        setIsPaused(true);

                    }} />
                </IonCol>
                <IonCol size='3'>
                    <IonLabel className="vertical-center play-align"  >
                        <IonIcon className="large-icon" icon={playSkipForwardCircle} size="large" onClick={() => {
                            player.skipToNext().then(() => setIsPlay(true)).catch(error => processPlayerError(error));
                        }} />
                    </IonLabel>

                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>

                </IonCol>
            </IonRow>
            {/* <IonModal //hidden={isAlertOpen} */}
                                
                                    {/* <a href='spotify:album:0sNOF9WDwhWunNAHPD3Baj'>Please start your Spotify player and click 'Play' button.</a> */}
                                    {/* <IonButton onClick={() => {
                                            setIsAlertOpen(false);
                                            console.debug('Confirm Okay');
                                        }}
                                        ></IonButton> */}
                                {/* </IonModal> */}
                <IonAlert
                    cssClass="alert"
                    isOpen={isAlertOpen}
                    message= "Please start your <a href='spotify:album:0sNOF9WDwhWunNAHPD3Baj'>Spotify player </a> and click 'Play' button."
                    buttons={[{
                        text: 'Okay',
                        handler: () => {
                            setIsAlertOpen(false);
                            console.debug('Confirm Okay');
                        }
                    }]}
                ></IonAlert>

        </IonGrid>

    )
}

export default Player;