import React,  { useEffect, useState } from 'react';
import { IonMenuToggle, IonGrid, IonRow, IonCol, useIonViewDidEnter, IonCard, IonCardContent, IonIcon, IonLabel, IonCardHeader, IonLoading, IonPage, IonHeader, IonButtons, IonMenuButton, IonTitle, IonToolbar, IonContent, IonButton, IonInput, IonToast, IonItem, IonFooter, IonItemDivider } from '@ionic/react';
// import { any } from 'prop-types';
import image from '../assets/images/bandoneon.png';
import login_image from '../assets/images/log_in-desktop-medium.png'
import Header from '../components/Header';
import { RouteComponentProps, Redirect } from 'react-router';
import { CONFIG } from '../constants';
//import SpotifyWebApi from 'spotify-web-api-js';
import * as  reactRouterDom from 'react-router-dom';
import { NavigationEvents } from 'react-navigation';
import SpotifyWebApi from 'spotify-web-api-js';
//import './player_utils'
import { menuSharp} from 'ionicons/icons'
import {homeSharp, logOutSharp, helpBuoy, help, helpBuoyOutline, helpBuoySharp, optionsOutline, optionsSharp, maleFemale, radio, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, logIn, musicalNotes, logInSharp, musicalNotesSharp, listOutline } from 'ionicons/icons';
import FooterMenu from '../components/FooterMenu';

const LoginPage: React.FC<RouteComponentProps<{ name: string; }>> = ({ match }) => {

  const [loggedIn, setLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [dashboardUrl, setDashboardUrl] = useState('');
  

  useIonViewDidEnter(() => {
    console.log("LoginPage did enter");
    console.log("loggedIn:");
    console.log(sessionStorage.getItem('loggedIn'));
    if (sessionStorage.getItem('loggedIn') == 'false' || !sessionStorage.getItem('loggedIn')) {
      console.log("Resetting loggedIn");
      setLoggedIn(false);
      setAccessToken('');
    } else {
      sessionStorage.setItem('loggedIn', 'true');
      setLoggedIn(true);
    }
  })

  const logOut = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.setItem('loggedIn', 'false')
    setLoggedIn(false);
  }

  useEffect(() => {
    console.log("LoginPage useEffect");
    
    var player = new SpotifyWebApi();

    var dbUrl = "partylonga/dashboard?";
    console.log('componentDidMount');
    var location = window.location;
    console.log(location.search);
    if (location.search) {
      console.log('location.search:' + location.search)
      var search_items = location.search.substring(1).split('&');
      //var access_token = search_items[0].split('=')[1];
      console.log('search_items:' + search_items);
      var query_dict = {}
      search_items.forEach((item, idx) => {
        console.debug('item:' + item);
        var kv = item.split('=');
        query_dict[kv[0]] = kv[1];
      });
      var islogged = 'true';
      if (sessionStorage.getItem('access_token') === "undefined") {
        islogged = 'false'
      }
      console.log("islogged:");
      console.log(islogged);
      // sessionStorage.setItem('access_token', query_dict['access_token']);
      // sessionStorage.setItem('refresh_token', query_dict['refresh_token']);
      // localStorage.setItem('user_id', query_dict['id']);
      // localStorage.setItem('display_name', query_dict['display_name']);
      // localStorage.setItem('product', query_dict['product']);
      // sessionStorage.setItem('loggedIn', islogged);

      player.setAccessToken(query_dict['access_token']);

      var devicesPromise = player.getMyDevices();
      devicesPromise.then((data) => {
        console.log(data)
        var dev = data.devices[0];
        data.devices.forEach((dev) => {
          if (dev && dev.is_active) {
            sessionStorage.setItem("device_id", (dev.id!).toString());

          }
        });
        console.log(sessionStorage.getItem("device_id"));
      }

      );


      setDashboardUrl(dbUrl + "access_token=" + query_dict['access_token'] + "&refresh_token=" + query_dict['refresh_token']);

      setLoggedIn(true);
    }

  }, []
  );

  const login_1 = () => {

    console.log("login_1");

  }

  var state = sessionStorage.getItem('state');
  if (state === 'loginClicked'){
      return <Redirect to='MilongaList' />
  }

  return (

    <IonPage>

      <Header title="Home"></Header>
      {/* <IonHeader>
      <IonToolbar>
        <IonGrid>
          <IonRow>
            <IonCol size='1'>
            <IonMenuButton></IonMenuButton>

            </IonCol>
            <IonCol>
            <IonTitle  class="header" color="primary">
              Login
            </IonTitle>
            </IonCol>
          </IonRow>
        </IonGrid>
        
        </IonToolbar>
        </IonHeader> */}
      <IonContent >

        <div className="ion-text-center">
          <img src={image} alt="logo" width="25%" />
        </div>
        <h1 className="ion-text-center conduit-title">Partylonga</h1>
        <div hidden={loggedIn} className="ion-text-center">
          <a className="ion-text-center conduit-title" href="/api/login" onClick={()=>{
            sessionStorage.setItem('state', 'loginClicked');
          }} >
            <img src={login_image} />
          </a>
        </div>
        <div className="ion-text-center" hidden={!loggedIn} >
          <a onClick={logOut} href='#'>Log out</a>
        </div>
        <IonItemDivider></IonItemDivider>
        {/* <div> */}
          {/* <div className="ion-text-center" hidden={!loggedIn} >
            <a href={dashboardUrl}>Open dashboard</a>
          </div> */}
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
              <div style={{ backgroundColor: 'var(--ion-color-primary)' }} >
            <h1 style={{ textAlign: 'center' }}>
              DJ LIKE A PRO!
          </h1>
          </div>
              </IonCol>
              

              </IonRow>
              <IonRow>
              <IonCol>


            {/* <div> */}
            <ul  style={{paddingLeft:"20%"}}>
              <li>
                Create professional grade milonga playlist in 1 click
              </li>
              <li>
                Play milonga songs with cortinas automatically shortened
              </li>
              <li>
                Customize playlist by selecting desired orchestras and/or singers
              </li>
              <li>
                Add cortinas of your choice or use pre-selected ones
              </li>
              <li>
                Listen to milonga formatted radio 24x7
              </li>
              <h1>
              Note: 
                            in order to use Partylonga web app you will need premium Spotify account and have to authorize the app to use Spotify to:
              </h1>
            </ul>
            {/* </div> */}
            </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
              

            </IonCol>
            </IonRow>
            <IonRow>
            <IonCol>

            <ol style={{paddingLeft:"20%"}}>
              <li >
                Access and store your email address and Spotify display name (for identification purposes only)
                </li>
              <li>
                Play the music and control playback
                </li>
              <li>
                Access your public playlists
                </li>
            </ol>
            </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>

            <div style={{paddingLeft:"20%"}}>
            <h2>
                  Disclaimer:
                </h2>
                  Partylonga only uses your informaion for purposes of providing functionality as per above list and will not share this data with any third party. 
                  The Spotify requires your consent before enabling access to your data by Partylonga. Full list of permissions on Spotify login page will appear once you click "Login to Spotify" button.
                  Your email address can be used to provide updates about Partylonga (once in two weeks at most)
            </div>
            </IonCol>

            </IonRow>
            </IonGrid>
            </IonItem>
            
            

{/* </div> */}

        {/* </div> */}
        {/* </IonItem> */}
      </IonContent>
    <FooterMenu></FooterMenu>
    </IonPage>

  )
}
export default LoginPage