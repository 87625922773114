import Menu from './components/Menu';
import Page from './pages/Page';
import MilongaForm from './pages/MilongaForm';
import MilongaPlaylist from "./pages/MilongaPlaylist";
import MilongaList from './pages/MilongaList';
import Login from './pages/Login';
import Help from './pages/Help';
import React, { useState, createContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IonGrid, IonRow, IonCol,IonApp, IonRouterOutlet, IonSplitPane, IonLifeCycleContext } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch, withRouter, useLocation } from 'react-router-dom';
import * as H from 'history'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import RadioPlayer from './pages/Radio';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Payment from './pages/Payment'
import {globalEventListener} from './pages/utils.js'
//import Vconsole from 'vconsole'

class PlaylistContextType {
  private _artlist: string[] = [];
  public get artlist(): string[] {
    return this._artlist;
  }
  public set artlist(value: string[]) {
    this._artlist = value;
  }
  public cortinas!: string;
  constructor(artists: string[], cortinas: string) {
    this.artlist = artists;
    this.cortinas = cortinas;
  }
  public updateArtlist(lst: string[]) {
    this._artlist = lst;
  }
  public setCortinalist(name: string) {
    this.cortinas = name;
  }
}
const defaultValue: PlaylistContextType = new PlaylistContextType([], "");

export const PlaylistContext = React.createContext<PlaylistContextType>(defaultValue);
//export const PlaylistContextProvider = PlaylistContext.Provider;
export const PlaylistContextConsumer = PlaylistContext.Consumer;




//{
//   cortinalist: "",
//   artlist: [""],
//   // updateArtlist: (lst) => { const artlist = lst},
//   // setCortinalist: (name) => {}
// });

// export const PlaylistContext = createContext(
//   {
//     artlist:[] as string[],
//     cortinalist: "",
//     setArtlis: React.Dispatch<React.SetStateAction<string[]>>,
//     setCortinalist
//   }

// );

export const PlaylistContextProvider = ({ children }) => {
  const [artlist, setArtlist] = useState([] as string[]);
  const [cortinalist, setCortinalist] = useState("");
  return (
    <PlaylistContext.Provider
      value={defaultValue}
    >
      {children}
    </PlaylistContext.Provider>
  );
};


const App: React.FC = () => {
  //let vConsole = new Vconsole();
  //const location = useLocation();

  const [selectedPage, setSelectedPage] = useState('');
  useEffect(() => {

    const pageId = 'App';
    const mlWrapper = document.getElementById(pageId);
    if (mlWrapper){
      if(mlWrapper.onclick == null)
        mlWrapper.addEventListener('click', (e)=>{
          globalEventListener(pageId, e);
        });
     }

    window.scrollTo(0,1);
    console.log("App useEffect");
    var location = window.location;
    console.log("location:");
    console.log(location);
    var search_items = location.search.substring(1).split('&');
    //var access_token = search_items[0].split('=')[1];
    console.log('search_items:' + search_items);
    var query_dict = {}
    search_items.forEach((item, idx) => {
      console.debug('item:' + item);
      var kv = item.split('=');
      query_dict[kv[0]] = kv[1];
    });
    var islogged = 'true';
    var access_token = sessionStorage.getItem('access_token');
    console.log('access_token:' + access_token);


    if (!access_token || access_token == 'undefined') {
      sessionStorage.setItem('access_token', query_dict['access_token']);
      sessionStorage.setItem('refresh_token', query_dict['refresh_token']);
      localStorage.setItem('user_id', query_dict['id']);
      localStorage.setItem('display_name', query_dict['display_name']);
      localStorage.setItem('product', query_dict['product']);
      access_token = sessionStorage.getItem('access_token');
      if (access_token === "undefined" || !access_token) {
        islogged = 'false'
      }
      console.log("islogged:" + query_dict['access_token']);
      console.log(islogged);
    }
    sessionStorage.setItem('loggedIn', islogged);
    sessionStorage.removeItem("loadedPl");
  })

  return (
    <IonApp id='App' >
      <IonReactRouter>
        <IonGrid>

        </IonGrid>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/page/MilongaForm" render={(props) => {
              // setSelectedPage("MilongaForm");
              return <MilongaForm {...props} />;
            }} exact={true} />
            {/* <Route path="/page/:name" render={(props) => {
              setSelectedPage(props.match.params.name);
              return <Page {...props} />;
            }} exact={true} /> */}
            {/* <Switch> */}
            <Route path="/page/Login" component={Login} />
            {/* <Route path="/page/Home" component={Login} /> */}
            {/* <Route path="/page/MilongaForm" component={withRouter(MilongaForm)} exact={true}/> */}
            {/* <Route path="/page/MilongaPlaylist/" component={sessionStorage.getItem("loadedPl") ? MilongaPlaylist : MilongaList} exact={true} /> */}
            <Route path="/page/MilongaPlaylist/:id" component={MilongaPlaylist} />
            <Route path="/page/MilongaList/" component={MilongaList} exact={true} />
            <Route path="/page/Radio" component={RadioPlayer} />
            <Route path="/page/Contact" component={Contact} />
            <Route path="/page/Dashboard" component={Dashboard} />
            <Route path="/page/Help" component={Help} />
            <Route path="/page/Payment/:id" component={Payment} />
            <Route path="/" render={(props) => <Redirect to="/page/Login" />} exact={true} />
            {/* </Switch> */}
          </IonRouterOutlet>

        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
