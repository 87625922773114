import {IonCol, IonRow, IonCardContent, IonCard, IonFooter, IonButton, IonButtons, IonContent, IonMenuButton, IonPage, IonToolbar, IonAlert, useIonViewDidEnter, useIonViewDidLeave, IonGrid } from '@ionic/react';
import React, { useEffect, useState, useContext, useRef } from 'react';
import './Page.css';
import { PlaylistContext } from '../App';
import axios from 'axios';
import { useLocation } from 'react-router';
import { globalEventListener, millisToMinutesAndSeconds } from './utils.js'
import SpotifyWebApi from 'spotify-web-api-js';
import { isNull, isUndefined } from 'util';

import Set from '../components/Set';
import DynamicProgressBar from '../components/DynamicProgressBar';
import FooterMenu from '../components/FooterMenu';
import PlongaPlayer from '../components/Player';
import PreviewPlayer from '../components/PreviewPlayer';

import "./Payment.css";

function MilongaPlaylist(props) {

  const playlistContext = useContext(PlaylistContext);
  var [playList, setPlaylist] = useState({ sets: [] });
  const [digest, setDigets] = useState("");
  //const [isLoading, setIsLoading] = useState(false);


  var [trackDict, setTrackDict] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [isDetailHidden, setIsDetailHidden] = useState(true);

  const plRef = useRef(trackDict);

  var [tmout, setTmout] = useState(5000);
  var tmoutRef = useRef(tmout);
  var trackProgressRef = useRef(0);
  var [trkProgr, setTrkProgr] = useState(0);

  var tracks = useRef([]);

  const [currentPreviewTrack, setCurrentPreviewTrack] = useState('');
  const [currentPreviewTrackState, setCurrentPreviewTrackState] = useState('paused');

  const [isPreview, setIsPreview] = useState(true);

  window.onresize = (evt) => {

    //console.log('showRightPane:' + showRightPane);
    var w = window.innerWidth;
    w = window.screen.availWidth / 2;
    //console.debug(window.screen.availWidth + '-' + window.innerWidth + '-' + w);
  }

  const getPlRef = () => {
    //console.debug("getPlRef");
    //console.debug(plRef);
    return plRef;
  };




  const player = new SpotifyWebApi();

  const fetchData = async () => {

    //console.debug("fetchData");
    //console.debug(props);

    const query = JSON.stringify({
      artists: playlistContext.artlist,
      cortinas: playlistContext.cortinas
    });
    //console.debug("props:");
    //console.debug(props);
    var id = -1;
    id = props.match.params.id;
    if (id == 0){
      id = sessionStorage.getItem("currentPl");
    }
    console.debug("id:" + id);

    // if (isNull(id) || isUndefined(id)) {
    //   id = sessionStorage.getItem("currentPl");
    //   console.debug("fetchData0")
    //   //console.debug('currentPl id:' + id);
    // }

    if (id === sessionStorage.getItem("loadedPl") && !(isNull(id) || isUndefined(id))) {
      console.debug("fetchData1")
      return;
    }

    var token = sessionStorage.getItem("refresh_token");
    var url = "/api/milonga/" + id + "?token=" + token;
    const config = {
      url: url,
      headers: {
        "Token": token
      }
    }
    if (id == -1 || isNull(id)) {
      //history.push("/page/MilongaList");
      console.debug("fetchData2")
      return;
    }
    axios(config).then(jsonData => {
      console.debug("jsonData");
      //console.debug(jsonData);
      //console.debug("jsonData.data:");
      //console.debug(jsonData.data);
      var description = jsonData.data.description;
      //console.debug("digest:");
      //console.debug(description);
      setDigets(description);
      var pl = jsonData.data;
      //console.debug("currentPl: " + id);
      //console.debug(pl);
      var trDict = {}
      pl.sets.map((set, idx) => {
        //console.debug(set);
        set.tandas.map((tanda, tidx) => {
          //console.debug(tanda);
          tanda.tanda_tracks.map((track, tridx) => {
            //console.debug(track);
            trDict[track.spotify_id] = track;
          })
        })
      });
      plRef.current = trDict;
      sessionStorage.setItem('loadedPl', pl.id);
      sessionStorage.setItem('loadedPlaylist', JSON.stringify(pl));
      sessionStorage.setItem('loadedPlDict', JSON.stringify(trDict));
      if (pl.paid_amount > 0 || pl.sets.length == 1){
        setIsPreview(false);
      } else {
        setIsPreview(true);
        setIsPreviewPlayerActive(true);
      }
      if (pl.id != playList.id)
      {
        setPlaylist(pl);
      }

    }).catch(error => {

      setAlertMsg("Authorization error. Please re-login.")
      sessionStorage.setItem('loggedIn', "false");
      sessionStorage.setItem('access_token', '');
      //console.debug("error:");
      //console.debug(error);
      //console.debug("location:");
      //console.debug(location);
      //console.debug("props.location.state:");
      //console.debug(props.location.state);
      setPlaylist(null);

    })
  };

  const [isPreviewPlayerActive, setIsPreviewPlayerActive] = useState(false);
  
  useIonViewDidLeave(()=>{

    setIsPreviewPlayerActive(false);

  });

  useIonViewDidEnter(() => {
    setIsPreviewPlayerActive(true);
    //document.getElementById('MilongaPlaylist').scrollIntoView();
    //console.debug("props:");
    //console.debug(props);
    //console.debug("location:");
    //console.debug(location);
    //console.debug("useIonViewDidEnter");


    //watchPlayer();
    player.getMyDevices().then((resp) => {
      //console.log("devices: ");
      //console.log(resp.devices);
      if (resp.devices.length > 0) {
        var device_id = resp.devices[0].id;
        //console.log("device_id: " + device_id);
        sessionStorage.setItem('device_id', device_id);
      }
    })




    setTrackDict({});

    //tracks = [];

  }, [props]);

  fetchData();

  const processPlayerError = async (error) => {


    if (error.response) {
      //console.debug("processPlayerError 1")
      var resp = JSON.parse(error.response);
      //console.debug(resp);
      //console.debug("message:" + resp.error.message);
      if (resp.error.message == 'The access token expired' || resp.error.message == "Invalid access token") {
        //console.debug("processPlayerError 2")
        try {
          var tokenResp = await axios.get("/api/refresh?refresh_token=" + sessionStorage.getItem("refresh_token"));
        } catch (error) {
          sessionStorage.setItem('loggedIn', 'false');
          setAlertMsg("Please relogin");
          //console.error(error);
          return;
        }
        //console.debug("processPlayerError 3")
        //console.debug(tokenResp);
        sessionStorage.setItem("access_token", tokenResp.data.access_token);
        player.setAccessToken(tokenResp.data.access_token);
      } else if (resp.error.message == 'Player command failed: No active device found') {
        //console.debug("setting alert message");
        setAlertMsg("Please start your Spotify player");
      } else {
        sessionStorage.setItem('loggedIn', 'false');
        setAlertMsg(resp.error.message);
      }
    }

  }



  const location = useLocation();
  useEffect(() => {
    const pageId = 'MilongaPlaylist'
    const mlWrapper = document.getElementById(pageId);
    if (mlWrapper) {
      if (mlWrapper.onclick == null)
        mlWrapper.addEventListener('click', (e) => {
          globalEventListener(pageId, e);
        });
    }

    //console.debug("useEffect");

    
    //console.debug("props:");
    //console.debug(props);
    //console.debug("location:");
    //console.debug(location);

  }, []);
  const digestConverter = (digest) => {
    //console.debug("digest:");
    //console.debug(digest);
    var artists = "";
    for (var art in digest.artists) {
      artists += art + "(" + digest.artists[art] + "), ";
    }
    var str = "Artists: " + artists + "; ";
    str += "Duration: " + millisToMinutesAndSeconds(digest.duration) + " min; ";
    str += playList.sets.length * 6 + " tandas";
    return str;
  };

  const savePlToSpotify = () => {

    if (isPreview) {
      return;
    }

    var descr = digestConverter(JSON.parse(digest));
    //console.debug("descr:" + descr);
    var plProps = {
      "name": "Partylonga \"" + name + "\"",
      "description": descr,
      "public": false
    }
    player.createPlaylist(localStorage.getItem('user_id'), plProps).then((resp) => {
      player.addTracksToPlaylist(resp.id, tracks.current).then(() => {
        setAlertMsg('Playlist \'Partylonga "' + name + '"\' was created and saved in your Spotify library!'
        );
      })
    }).catch((error) => {
      processPlayerError(error, setAlertMsg)
    });
    //player.play();
  }

  const trackCb = (spotify_id, trackState) => {
    setCurrentPreviewTrack(spotify_id);
    setCurrentPreviewTrackState(trackState);
  }

  const [playingTrack, setPlayingTrack] = useState({ track: {}, isPlaying: false });
  const trackStateChanged = (trackObj) => {
    // trackState.isPlaying
    // trackState.track
    //console.debug("trackStateChanged");
    //console.debug(trackObj);
    //trackCb(trackObj.track.eltId, currentPreviewTrackState.isPlaying?'playing':'paused');
    setPlayingTrack(trackObj);
  }


  if (playList) {
    //console.debug(trackDict);
    tracks.current = [];
    //plRef.current = {};
    //console.debug("playlist:")
    //console.debug(playList);
    var name = "";
    if (digest) {
      var j_digest = JSON.parse(digest);
      name = j_digest.name;
      var duration = "~" + playList.sets.length * 60;
      var descr = digestConverter(JSON.parse(digest)).replace(';', '');
      if (j_digest.duration) {
        duration = millisToMinutesAndSeconds(j_digest.duration)
      }

      //console.debug(playList.sets.length);
      //console.debug("digest.duration:" + digest.duration == true);
      //console.debug("duration:" + duration);
    }

    // var previewPlayer = React.createRef()
    // new PreviewPlayer({currentPreviewTrack:{id: currentPreviewTrack, state: currentPreviewTrackState}, getPlRef:getPlRef, tracks:tracks, pl:plRef})


    return (
        <IonPage id='MilongaPlaylist'>
          <IonCard hidden={isDetailHidden}>
            <IonCardContent>
              {
                descr
              }
            </IonCardContent>
            <IonButton fill="outline" onClick={() => setIsDetailHidden(true)}>Ok</IonButton>
          </IonCard>
            <IonToolbar > 

              <IonButtons slot="start">
                <IonMenuButton  />
              </IonButtons>

              <IonGrid>
                <IonRow>
                  <IonCol >
                <div onClick={() => {
                setIsDetailHidden(false); }} style={{height: 25}} className="ion-text-center" >
                  <h1 >{name}</h1>
                  </div>
                  </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
            {isPreview && <div style={{fontSize: 12, height: 15, paddingTop: 5}} className="ion-text-center">PREVIEW</div>}
            </IonCol>
            </IonRow>
            </IonGrid>
              
              <DynamicProgressBar getProgress={() => trackProgressRef.current}></DynamicProgressBar><br />
            </IonToolbar>
          <IonContent>
            {
              playList.sets.sort((a, b) => a.valence - b.valence).map((set, setIdx) =>
                <Set key={setIdx} 
                set={set} 
                setIdx={setIdx} 
                playingTrack={playingTrack} 
                trackCb={trackCb} 
                tracksRef={tracks} 
                plRef={plRef} 
                player={player} 
                playList={playList} 
                processPlayerError={processPlayerError} name={name} 
                setAlertMsg={setAlertMsg} 
                savePlToSpotify={savePlToSpotify} 
                isPreview={isPreview}>
                  
                </Set>
              )
            }
          </IonContent>
          <IonFooter>
            <IonToolbar>
              {isPreview
                ? 
                  <PreviewPlayer 
                    onTrackStateChanged={trackStateChanged} 
                    playingTrack={playingTrack} 
                    currentPreviewTrack={{ id: currentPreviewTrack, state: currentPreviewTrackState }} 
                    getPlRef={getPlRef} 
                    tracks={tracks} pl={plRef} 
                    isActive = {isPreviewPlayerActive}
                    >
                      
                  </PreviewPlayer>
                  //null
                : 
                  <PlongaPlayer
                    setAlertMsg={setAlertMsg}
                    player={player}
                    getPlRef={getPlRef}
                    trkProgr={trkProgr}
                    trackProgressRef={trackProgressRef}
                    tmoutRef={tmoutRef}
                    processPlayerError={processPlayerError}
                    playList={playList}
                    tracks={tracks}>

                  </PlongaPlayer>
              }
            </IonToolbar>
            <DynamicProgressBar getProgress={() => trackProgressRef.current}></DynamicProgressBar>
          </IonFooter>
          <IonAlert
            cssClass="alert"
            isOpen={alertMsg != ""}
            message={alertMsg}
            buttons={[{
              text: 'Okay',
              handler: () => {
                setAlertMsg("");
                //console.debug('Confirm Okay');
              }
            }]}
          >

          </IonAlert>

          <FooterMenu></FooterMenu>
        </IonPage>

    );
  } else {
    return (<IonPage><IonContent>No data</IonContent></IonPage>)
  }
};

export default MilongaPlaylist;
