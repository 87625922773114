import React, { useState } from 'react';
import Track from './Track';
import axios from 'axios';
import {IonCard, IonItem, IonButton, IonCol, IonText} from '@ionic/react';
import { thumbsUpSharp, thumbsDownSharp, thumbsUpOutline, thumbsDownOutline} from 'ionicons/icons'
import {millisToMinutesAndSeconds } from '../pages/utils.js'
import Tanda from './Tanda'
import CheckoutForm from './CheckoutForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "../pages/Payment.css";

const Set = ({set, setIdx, plRef, playingTrack, trackCb, tracksRef, playList, name, savePlToSpotify, isPreview}) => {
    var [isRendered, setIsRendered] = useState(false);
    const [digest, setDigets] = useState("");

    const url = "/page/Payment/" + playList.id;
    const promise = loadStripe("pk_test_51HJrNeFTe9cEFhnnDPkZuBAdXzXLRRtJKHmUfiitNgXNWsSABxFzzE3W5lma9UQFAfiwPNplrbEpyNsGcbbtUjpN00rQX1fGe9");
    const [isPaymentOpen, setIsPaymentOpen] = useState(true);
    
    const digestConverter = (digest) => {
        console.debug("digest:");
        console.debug(digest);
        var artists = "";
        for (var art in digest.artists) {
          artists += art + "(" + digest.artists[art] + "), ";
        }
        var str = "Artists: " + artists + "; ";
        str += "Duration: " + millisToMinutesAndSeconds(digest.duration) + " min; ";
        str += playList.sets.length * 6 + " tandas";
        return str;

      };
    
    const createPayment = ()=>{
      setIsPaymentOpen(false);
    }

    console.log(set.id);
    return (
      <div key={set.id} style={{margin:0, minWidth: '100%'}} >
        <IonItem key={set.id}>
          {!isPreview 
            ?
            <IonButton expand="block" onClick={savePlToSpotify}><IonCol></IonCol> <IonCol ><IonText >Follow "{name}" on Spotify</IonText></IonCol>
            </IonButton>
            :
            // <IonButton onClick={()=>{
            //   createPayment();
            // }} expand="block" ><IonCol></IonCol> <IonCol ><IonText >Purchase the playlist for $5</IonText></IonCol>
            // </IonButton>
            <IonItem routerLink={url}><a className="preview-link">Purchase playlist "{name}"</a></IonItem>
          }
        </IonItem>
        <div style={{margin:0}} >
          {
            set.tandas.map((tanda, tandaIdx) => 
            <Tanda tanda={tanda} key={tandaIdx} idx={tandaIdx} setIdx={setIdx} plRef={plRef} playingTrack={playingTrack} trackCb={trackCb} tracksRef={tracksRef} isPreview={isPreview}></Tanda>

            )
          }
        </div>
        {/* <IonCard hidden={isPaymentOpen}>
          <div className='Payment'>
        <Elements stripe={promise}>
          <CheckoutForm ></CheckoutForm>
          </Elements>
          </div>
        </IonCard> */}

      </div>
    )

}
export default Set