import {IonText, IonList, IonItemDivider, IonButton, IonCardContent, IonCard, IonItem, IonIcon, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import { radio, reloadCircleSharp, informationCircleSharp } from 'ionicons/icons'

const Page: React.FC<RouteComponentProps<{ name: string; }>> = ({ match }) => {
  const [isDetailHidden, setIsDetailHidden] = useState(true);
  const [detailId, setDetailId] = useState('');
  const toggleCard = (cardId) => {
    //
    var card = document.getElementById(cardId);

    if (card)
      card.hidden = !card.hidden;
    //card.setAttribute('hidden', "false");


  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{match.params.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <ExploreContainer name={match.params.name} /> */}
        <IonList>
          <IonItem detail button onClick={() => toggleCard('about')}>
            1. About Partylonga
            </IonItem>
          <IonCard id='about' hidden>
            <IonItem>
              <IonLabel></IonLabel>
            </IonItem>

            <IonCardContent>
              <p>
                For all Argentine tango dancers one of the main appeals of the dance is the wonderful and beautiful music. It is arguably the main motivation to get out on the dance floor and submerge into the dance.
                The Partylonga's main purpose is to allow organizers of the tango events (milongas, practicas, classes) of any scale having easy way to create and play a list of Argentine tangos, valses and milongas, structured in a proper, traditional format.
                By traditional format we imply music broken down in tandas and separated by cortinas, where tanda is a set of songs related by character and artist/orchestra, including 3 to 4 songs of the same genre. 
                The tandas are ordered as 2 tango tandas, followed by either milonga or vals tanda. The cortina, as the name implies indicates the end of one tanda and beginning of the next one. Normally cortina is a non-tango related music, signalling to dancers that it is time to break the imbrace and change the partner.
                While traditional tango music is represented by rather limited set of songs played at milongas worldwide, the choice of cortina is totally up to DJ and cortina often serves as an embelishement affecting the mood and whole atmosphere of milonga.
                Partylonga web app allows creation of the playlists from selection of more than 500 tangos, milongas and valses played by 20 different orchestras. Cortinas are added to tandas automatically by special algorithm matching the character of following tanda and character of cortina.
                To enable customization in accordance with DJ preferences, cortinas can be selected from DJ's personal playlist. Each playlist created is unique even if you select the same orchestras. It allows you to quickly evaluate the playlist and if you don't like it, create another in no time.
                Our future plans include additional features and improved playlist building algorithm as well as other features.
              </p>
            </IonCardContent>
            <div className="ion-text-center">
              <IonButton fill="outline" onClick={() => toggleCard('about')}>Ok</IonButton>
            </div>
          </IonCard>

          <IonItem detail button onClick={() => toggleCard('tips')}>
            2. Features and Tips
            </IonItem>
          <IonCard id='tips' hidden>
            <IonCardContent>
              <h1>Create milonga playlist with cortinas</h1>
              <ul>
                <li>Set duration</li>
                <li>Select artists/orchestras</li>
                <li>Select cortinas playlist from drop down or use default cortinas</li>
                <li>Click "Submit" button to create playlist. In couple of seconds you will see playlist created at your request and will be able to play the music using player controls at the bottom</li>

              </ul>
              <h1>Preview songs in playlist</h1>
              When playlist page is open, you can click on the song title to open preview card and play 30 sec song preview.
              <h1>Customize cortinas</h1>
              <p>
                You can use your own playlist of cortinas to be added to the milonga playlist. Partylonga recognizes cortinas in your Spotify library if they are added to public playlist and playlist includes word "cortina" it its name.
                For instance, if you have in your library playlist with a title "My favorite <IonText color='secondary'>cortinas</IonText>", you will see the name of this playlist when selecting cortinas in "Create Milonga Playlist" page and will be able to select it as a source of cortinas
              </p>
              
              <h2>Play milonga tracks</h2>
              <ul>
                <li>Open page "My Partylongas" and select playlist from the name from the list. You will see page with a playlist and player controls at the bottom</li>
                <li>To play from beginning click reset button <IonIcon icon={reloadCircleSharp} size='large'></IonIcon></li>
                <li>Partylonga player controls allow only to play, reset and step forward. For finer control use Spotify player</li>
                <li>When you use Partylonga player to play milonga playlist it controls duration of cortinas (default is 45 sec)</li>
              </ul>
              <h1>Now playing</h1>
              Use this menu button to open currently playing playlist. 
              If you started playing the music in playlist and then navigated away from the playlist page, you can easily return there by clicking "Now playing" menu button

              <h1>Toggle </h1>

              <h1>Save generated playlist in your Spotify collection</h1>
              <ul>
                <li>Click "Follow ..." button. The playlist will appear in your library</li>
                <li>The generated playlist will be available for playback next time you open Partylonga App</li>
              </ul>
            </IonCardContent>
            <div className="ion-text-center">
              <IonButton fill="outline" onClick={() => toggleCard('tips')}>Ok</IonButton>
            </div>
          </IonCard>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Page;
