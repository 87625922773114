import React, { useState } from 'react';
import { IonAlert, IonButton, IonContent } from '@ionic/react';

// export const SubmitAlert: React.FC = (artists) => {
//     const [showSubmitAlert, setShowSubmitAlert] = useState(true);
//     setShowSubmitAlert(true);
//     var arts = artists as []
//     return (
//         <IonContent>
//         <IonAlert
//           isOpen={showSubmitAlert}
//           onDidDismiss={() => setShowSubmitAlert(false)}
//           header={'Prompt!'}
//           inputs={arts}
//           buttons={[
//             {
//               text: 'Cancel',
//               role: 'cancel',
//               cssClass: 'secondary',
//               handler: () => {
//                 console.log('Confirm Cancel');
//               }
//             },
//             {
//               text: 'Ok',
//               handler: () => {
//                 console.log('Confirm Ok');
//               }
//             }
//           ]}
//         />
//         </IonContent>
//         );
//     }

     export default <IonContent/>;