import React from 'react';
import { IonGrid, IonRow, IonCol, IonToolbar, IonTitle, IonHeader, IonMenuButton } from '@ionic/react';
import '../pages/Page.css'

class Header extends React.Component<any, any> {
  render() {
    return (
      <IonHeader>
        <IonToolbar >
          <IonGrid>
            <IonRow>
              <IonCol size='2'>
                <IonMenuButton></IonMenuButton>

              </IonCol>
              <IonCol >
                <IonTitle class="header" color="primary">
                  <h1>{this.props.title}</h1>
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

    )
  }
}
export default Header