import { IonAlert, IonRow, IonLabel, IonIcon, IonCol, IonGrid, IonModal, IonButton } from '@ionic/react';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { isNull } from 'util';
import { reloadCircleSharp, pauseCircle, playCircleOutline, playSkipForwardCircle } from 'ionicons/icons';

class PreviewPlayer extends React.Component {
    constructor(props) {
        super(props);
        
        this.audio = new Audio();
        this.handleClick = this.handleClick.bind(this);
        this.playNext = this.playNext.bind(this);
        this.state = { 
            isPlaying: false,
            currentPl: null,
            currentPreviewTrack: '',
            playingTrack: null,
            isActive: props.isActive
        }

    }

    componentDidUpdate(prevProps, prevState) {
        
        //console.debug("componentDidUpdate");

        //console.debug(prevProps);
        //console.debug(prevState);
        //console.debug(this.props);
        //console.debug(this.props.getPlRef().current);
        //console.debug(this.props.currentPreviewTrack);
        if(!this.props.isActive){
            this.audio.pause();
            this.audio.className = 'pp_pause';
            if (this.state.isPlaying){
                this.setState({ isPlaying: false })
            }
            return;
        }

        if (this.state.playingTrack !== this.props.playingTrack){
            //console.debug("this.props.playingTrack");
            //console.debug(this.props.playingTrack);
        
            //console.debug("this.state.playingTrack");
            //console.debug(this.state.playingTrack);
        
            //this.handleClick(null);
            this.setState((state, props)=>({playingTrack: props.playingTrack}));
            //console.debug("componentDidUpdate_0")
            return;
        }

        if (prevProps === this.props || this.props.currentPreviewTrack.id == ""){
            //console.debug("componentDidUpdate_01")
            return;
        }

        //if (prevProps.)

        if (this.props.currentPreviewTrack.id !== this.state.currentPreviewTrack && this.props.currentPreviewTrack.id !== this.state.playingTrack.track.eltId) {
            //console.debug("componentDidUpdate_02")
            this.state.currentPreviewTrack = this.props.currentPreviewTrack.id;
            var track = this.props.getPlRef().current[this.props.currentPreviewTrack.id];
            //console.debug(track);
            if (track != undefined) {
                //console.debug("componentDidUpdate_03")
                if (this.indexDict == undefined) {
                    //console.debug("componentDidUpdate_04")
                    this.handleClick(null);
                }
                var idx = this.indexDict[track.spotify_id];
                this.state.currentIdx = idx - 1;
                if (this.props.currentPreviewTrack.state === 'paused')
                    //console.debug("componentDidUpdate_05")
                    this.playNext();
            } else if (this.props.currentPreviewTrack.state === 'playing') {
                //console.debug("componentDidUpdate_06")
                this.audio.pause();
            }
        } else {
            //console.debug("componentDidUpdate_07");
            if (this.props.currentPreviewTrack.state === 'playing') {
                if (this.state.isPlaying){
                    //console.debug("componentDidUpdate_08");
                    this.handleClick(null);
                }
            }
            if (this.props.currentPreviewTrack.state === 'paused') {
                //console.debug("componentDidUpdate_09");
                if (!this.state.isPlaying){
                    this.handleClick(null);
                }
            }
        }
    }

    convertToPlaylist = (tracks, pl) => {
        //console.debug("convertToPlaylistOptions");
        //console.debug(pl);
        var rslt = [];
        var indexDict = {};
        var cntr = 0;
        tracks.map((track_id) => {
            var trId = track_id.split(":")[2];
            var tr = pl[trId];

            var url = tr.preview_url.replace('"', '');
            var plItem = {
                source: url,
                artist: tr.artist,
                title: tr.title,
                id: tr.id,
                eltId: trId
            }

            rslt.push(plItem);

            indexDict[trId] = cntr;
            cntr++;

        })
        this.indexDict = indexDict;
        return rslt;

    }

    handleClick = (e) => {


        if (!this.state.isPlaying && this.audio.paused) {
            if (this.state.currentPlId != sessionStorage.getItem('loadedPl')) {
                var plRef = this.props.getPlRef();
                const playlist = this.convertToPlaylist(this.props.tracks.current, this.props.getPlRef().current);
                this.playlist = playlist;
                this.state.currentIdx = 0;
                this.state.currentPl = plRef;
                this.state.currentPlId = sessionStorage.getItem('loadedPl');
            }
            if (this.playlist == undefined) 
                return;
            var track = this.playlist[this.state.currentIdx];
            this.currentTrack = track;

            this.audio.src = track.source;
            this.audio.play();
            this.audio.className = 'pp_play';
            this.setState({ isPlaying: true })

        } else {
            this.audio.pause();
            this.audio.className = 'pp_pause';
            this.setState({ isPlaying: false })
            
        }
        this.props.onTrackStateChanged({isPlaying: !this.audio.paused, track: this.currentTrack});

    }

    playNext() {

        if (this.playlist == undefined || this.state.currentPlId != sessionStorage.getItem('loadedPl')) {
            this.audio.pause();
            this.state.isPlaying = false;
            this.handleClick(null);
            return;
        }
        var track;
        if (++this.state.currentIdx < this.playlist.length) {
            track = this.playlist[this.state.currentIdx];
            var src = track.source;
            this.audio.src = src;

            this.audio.play();
            this.currentTrack = track;
            if (!this.state.isPlaying) {
                this.setState({ isPlaying: true });
            }
            this.setState((state, props)=>({playingTrack: {isPlayng: true, track:track}}));
            this.props.onTrackStateChanged({isPlaying: !this.audio.paused, track: track});
        }
    }

    componentDidMount() {
        //this.audio.on
        //console.debug("componentDidMount");
        this.audio.className = 'pp_pause';
        this.state.currentIdx = 0;
        this.audio.pause();
        this.audio.onended = () => {
            this.state.currentPreviewTrack = '';
            setTimeout(this.playNext, 1000);

        }

    }

    render() {


        return <div>
            {/* <audio id='preview_audio' src={this.props.src}></audio> */}
            {/* <button onClick={(e)=>this.handleClick(e)}>{this.state.isPlaying ? 'Pause':'Play'}</button> */}
            <IonGrid className='ion-text-center ion-no-margin'>
                <IonRow>
                    <IonCol size='3'>
                        <IonLabel className='vertical-center ion-text-center'>
                            <IonIcon icon={reloadCircleSharp} size="large" onClick={(e) => {
                                this.state.currentIdx = 0;
                                this.state.isPlaying = false;
                                if (this.currentTrkElt != undefined) {
                                    this.currentTrkElt.className = this.currentTrkEltOrigClass;
                                    
                                }
                                this.handleClick(e);

                            }} /></IonLabel>
                    </IonCol>
                    <IonCol size='4' >
                        <IonLabel className='ion-text-center'><IonIcon className="large-icon vertical-center" onClick={(e) => this.handleClick(e)} icon={this.state.isPlaying ? pauseCircle : playCircleOutline}></IonIcon>
                        </IonLabel>
                    </IonCol>
                    <IonCol size='3'>
                        <IonLabel className="vertical-center play-align"  >
                            <IonIcon className="large-icon" size='large' icon={playSkipForwardCircle} onClick={(e) => {
                                this.playNext();

                            }}></IonIcon>
                        </IonLabel>
                    </IonCol>
                </IonRow>
            </IonGrid>

        </div>
    }

}

export default PreviewPlayer;