import React, { useState, useEffect } from "react";
import {useIonViewDidEnter, IonHeader, IonItem, IonPage, IonContent} from '@ionic/react';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Link } from 'react-router-dom';


function CheckoutForm (props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const [isPaid, setIsPaid] = useState(false);

  var playlist = JSON.parse(sessionStorage.getItem('loadedPlaylist'));

  useIonViewDidEnter(() => {
    console.debug("useIonViewDidEnter")
    console.debug(props);
    //var id = 390;
    var id = props.plId;
    playlist = JSON.parse(sessionStorage.getItem('loadedPlaylist'));
    var descr = JSON.parse(playlist.description);
    var name = descr.name;
    // Create PaymentIntent as soon as the page loads
    fetch("api/create-payment-intent", {
        method: "POST",
        headers: {              
          "Content-Type": "application/json"
        },
        body: JSON.stringify({items: [{ 
          id:  id,
          name: name
        }]})
      })
      .then(res => {
        console.debug(res);
        var data = res.json();
        console.debug(data);
        return data;
      })
      .then(data => {
        console.debug(data);
        if (data.error){
          props.returnStatus({'amount': 0, 'isPaid': false, error: "The payment cannot be processed!"});  
        }
        props.returnStatus({'amount': data.amount/100, 'isPaid': data.isPaid});
        
        setIsPaid(data.isPaid);
        setClientSecret(data.clientSecret);
      });
  }, [props]);

  const updatePlaylistStatus = ()=>{
    fetch("api/update_playlist_status", {
      method: "PUT",
      headers: {              
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id:  props.plId
      })
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      //setClientSecret(data.clientSecret);
      sessionStorage.removeItem("loadedPl")
      console.debug(data.id);
      
    });
  }

  // useEffect(()=>{
  //   console.debug(props);
  //   var id = props.plId;
  //   //var id = props.match.params.id;
  //   // Create PaymentIntent as soon as the page loads
  //   // fetch("api/create-payment-intent", {
  //   //     method: "POST",
  //   //     headers: {
  //   //       "Content-Type": "application/json"
  //   //     },
  //   //     body: JSON.stringify({items: [{ id:  id+1}]})
  //   //   })
  //   //   .then(res => {
  //   //     return res.json();
  //   //   })
  //   //   .then(data => {
  //   //     setClientSecret(data.clientSecret);
  //   //   });

  // }, [])

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "13px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    var card = elements.getElement(CardElement)
    console.debug("card");
    console.debug(card);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
        billing_details: {
          name: ev.target.name.value
        }
        
      }
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      updatePlaylistStatus()
    }
  };
  return (
<>
    {isPaid == true
    ? 
      <div> 
        <div>You already purchased this playlist</div>
      <Link style={{alignSelf:"center"}} to="/page/MilongaList"   > Go to paltylonga list</Link>
      </div>
    :

    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      {/* <CardNumberElement id='card-num-elt' options={cardStyle} onChange={handleChange} />
      <CardExpiryElement id='card-exp-elt' options={cardStyle} onChange={handleChange} />
      <CardCvcElement id='card-cvc-elt' options={cardStyle} onChange={handleChange} /> */}
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay"
          )}
        </span>
      </button>
      <div style={{alignSelf:"center"}} >
        {!succeeded
        ?
          <Link style={{alignSelf:"center"}} to="/page/MilongaList"   > Cancel</Link>
        : null
        }
      </div>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p  className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded. Thank you! <br/>
        <Link style={{alignSelf:"center"}} to="/page/MilongaList"   > Go to paltylonga list</Link>

      </p>
    </form>
}
  </>
  );
}

export default CheckoutForm;