import { IonNote, IonCardContent, IonCard, IonToggle, IonCol, IonRow, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonRouterLink, IonGrid, IonChip, IonIcon } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import { millisToMinutesAndSeconds } from './utils.js'

import { isNull } from 'util';
import SpotifyWebApi from 'spotify-web-api-js';
import axios from 'axios';

function Dashboard() {

  const [error, setError] = useState('');
  const [isPlEnabled, setIsPlEnabled] = useState(false);
  const [skipCort, setSkipCort] = useState(-1);
  const player = new SpotifyWebApi();
  const [playlists, setPlayLists] = useState([]);
  player.setAccessToken(sessionStorage.getItem('access_token'));
  var user_id = localStorage.getItem('user_id');

  useEffect(

    () => {

      const fetchData = async () => {
        const config = {
          url: "/api/pl_spotify",
          headers: {
            "Token": sessionStorage.getItem("access_token")
          }
        }
        // const jsonData = await axios(config);
        // var rslt = jsonData;
        // console.log(rslt.data.items);
        // setPlayLists(rslt.data.items);
        fetch("/api/pl_spotify", config).then((data) =>
          data.json()
        ).then(data => {
          console.log(data);
          setPlayLists(data.items)
        }
        )
      }

      // var rslt = player.getUserPlaylists(user_id, null, (error, data)=>{
      //   console.log(error);
      //   console.log(data);
      //   //setPlayLists(data.items);
      //   return data.items;
      // })

      // rslt.then((data)=>
      //   setPlayLists(data)
      // )

      // const fetchData = async () => {
      //   //fetch('https://api.spotify.com/v1/me/playlists')

      // }

      fetchData();

    }, []
  );

  return (
    <IonPage >
      <IonHeader hidden={isPlEnabled}>
        <IonToolbar style={{ textAlign: 'center' }}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle style={{ marginRight: '20%' }}><IonLabel className="ion-text-center"><h1>Spotify playlists</h1></IonLabel>
          </IonTitle>
        </IonToolbar>

      </IonHeader>

      <IonContent hidden={isPlEnabled}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large"></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem className="ion-text-center" hidden={error.length == 0}>
          <IonLabel>{error}</IonLabel>
        </IonItem>
        <IonList hidden={error != ''}>
          {playlists.map((pl, idx) => {
            //{"artists": {"Angel D'Agostino": null, "Anibal Troilo": null, "Carlos Di Sarli": null, " Domingo Federico": null, "Domingo Federico": null, "Edgardo Donato": null, "Juan D'Arienzo": null, "Lucio Demare": null, "Miguel Calo": null, "Osvaldo Fresedo": null, "Pedro Laurenz": null}, "description": "Milonga", "id": 315, "date_time": "03/30/20 23:36:01"} 
            //console.log(pl.items.description);
            //var digest = JSON.parse(milonga.description);
            //var url = { pathname: "/page/MilongaPlaylist/" + milonga.id };
            // var artists = "";
            // for (var art in digest.artists) {
            //   artists += art + "(" + digest.artists[art] + "), ";
            // }
            return (
              <IonItem key={pl.id} className="milonga" detail >
                <IonGrid>

                  <IonRow className="milonga"   >
                    <IonCol  >
                      {/* <IonItem routerLink={url}><a>{digest.name}</a></IonItem> */}
                      <IonItem>{pl.name}</IonItem>
                    </IonCol>

                    {/* <IonCol  size='auto' onClick={() => {
                            //console.debug("track.id clicked: " + track.spotify_id)
                            setCardId(milonga.id);
                            console.debug("cardId: " + cardId)
                            if (!isCardVisible) {
                              setIsCardVisible(true);
                            }
                          }}>       
                        <div style={{textAlign: 'center'}} hidden={(isCardVisible && cardId === milonga.id )}>
                          <IonCard className="padding"><p style={{textAlign: 'center'}}>{new Date(digest.date_time).toLocaleString("en-US", { year: 'numeric', month: 'numeric', day: 'numeric', hour:'numeric', minute:'numeric' })} </p></IonCard>
                          <IonCard className="padding" style={{textAlign: 'center'}}>{millisToMinutesAndSeconds(milonga.duration_ms)} min</IonCard>
                        </div>
                        </IonCol>
                        <IonCol className='ion-hide-sm-down right'  >
                          <IonItem>
                        <IonCard>{artists}</IonCard>
                        </IonItem>
                      </IonCol>                         */}
                  </IonRow>
                </IonGrid>
                {/* <IonCard hidden={!(isCardVisible && cardId === milonga.id )}>
                                                  <IonCardContent> 
                                                    {artists}
                                                  </IonCardContent>
                                                  <IonButton fill="outline" onClick={() => {
                                                    setIsCardVisible(false);
                                                  }}>Ok</IonButton>
                                                </IonCard> */}

              </IonItem>
            );
          }
          )}
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
