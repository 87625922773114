import React, { useState } from 'react';
import {IonItem, IonFooter, IonIcon, IonButtons, IonToolbar, IonButton } from '@ionic/react';

import { homeSharp, help, radio, musicalNotes, listOutline, maleFemale } from 'ionicons/icons';

const FooterMenu = () => {
    return (
        <IonFooter style={{height:'7%'}} >
                <IonItem>
                <IonButtons style={{margin: '1px'}}>
                    <IonButton routerLink="/page/Login">
                        <IonIcon icon={homeSharp}></IonIcon>
                    </IonButton>

                    <IonButton routerLink="/page/MilongaList" >
                        <IonIcon icon={maleFemale}></IonIcon>
                    </IonButton>
                    <IonButton routerLink="/page/MilongaPlaylist/0" >
                        <IonIcon icon={musicalNotes}></IonIcon>
                    </IonButton>
                    <IonButton routerLink="/page/MilongaForm">
                        {/* <IonLabel style={{fontSize:"small", textTransform: "none"}}>Create Playlist</IonLabel> */}
                        <IonIcon icon={listOutline}></IonIcon>
                    </IonButton>

                    <IonButton routerLink="/page/Radio/" >
                        <IonIcon icon={radio}></IonIcon>
                    </IonButton>
                </IonButtons>
                {/* </IonItem> */}
                <IonButtons slot='end'>

                    <IonButton routerLink="/page/Help" >
                        <IonIcon icon={help}></IonIcon>
                    </IonButton>


                </IonButtons>
                </IonItem>
        </IonFooter>
    );
}

export default FooterMenu;